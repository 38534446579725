import React from 'react';
import { AbstractStrapDraw } from './strap/AbstractDraw';

export class CollarGraphicsScene {
    public element: HTMLCanvasElement;
    public collar: AbstractStrapDraw<any>;
    public disableBackground: boolean;
    public neckSize: number;
    public scale: number;
  
    constructor(collar: AbstractStrapDraw<any>, canvas) {
      this.element = canvas;
      this.collar = collar;
      this.disableBackground = false;
    }
  
    get width() {
      return this.element.width;
    }
  
    get height() {
      return this.element.height;
    }
  
    get context() {
      return this.element.getContext('2d');
    }
  
    totalWidth() {
      return this.neckSize + 1.0;
    }
  
    redraw() {
      const collar = this.collar;
  
      var ctx = this.context;
      this.scale = Math.min(this.width / (collar.w + 6), this.height / (collar.h))
  
      ctx.clearRect(0, 0, this.width, this.height);
  
      if (!this.disableBackground) {
        ctx.fillStyle = "rgb(255,255,255)";
        ctx.fillRect(0, 0, this.width, this.height);
      }
  
      ctx.save();
      ctx.scale(this.scale, this.scale);
      ctx.translate(this.width / 2 / this.scale, this.height / 2 / this.scale);
      ctx.translate(-(collar.w / 2 + collar.x), -(collar.h / 2 + collar.y));
  
      if (!this.disableBackground)
        this.drawGrid(ctx);
  
      collar.draw(ctx);
      ctx.restore();
    }
  
    drawGrid(ctx) {
      //Draw background grid
      ctx.strokeStyle = "rgb(220,220,220)";
  
      for (let i = -30; i < 30; i += 1) {
        ctx.lineWidth = 0.5 / this.scale;
  
        //Horizontal
        ctx.beginPath();
        ctx.moveTo(-30, i);
        ctx.lineTo(30, i);
        ctx.stroke();
  
        //Vertical
        ctx.beginPath();
        ctx.moveTo(i, -30);
        ctx.lineTo(i, 30);
        ctx.stroke();
      }
  
      ctx.strokeStyle = "rgb(150,150,150)";
  
      for (let i = -3 * 10; i < 3 * 10; i += 3) {
        ctx.lineWidth = 1 / this.scale;
  
        //Horizontal
        ctx.beginPath();
        ctx.moveTo(-30, i);
        ctx.lineTo(30, i);
        ctx.stroke();
  
        //Vertical
        ctx.beginPath();
        ctx.moveTo(i, -30);
        ctx.lineTo(i, 30);
        ctx.stroke();
      }
    }
  }
  
  interface CollarCanvasBaseProps {
    draw: AbstractStrapDraw<any>;
    disableBackground: boolean;
    height: number;
    width: number;
  }
  
  interface CollarCanvasBaseState {
    width: number;
    height: number;
  }
  
  
  export class CollarCanvasBase extends React.Component<CollarCanvasBaseProps, CollarCanvasBaseState> {
    public intervalHandle: NodeJS.Timeout;
    public canvasRef: React.RefObject<HTMLCanvasElement>;
  
    constructor(props) {
      super(props);
      this.updateSize = this.updateSize.bind(this);
      this.canvasRef = React.createRef();
  
      this.state = {
        width: 0,
        height: 0
      }
    }
  
    componentDidMount() {
      this.updateCanvas();
  
      this.intervalHandle = setInterval(() => this.updateSize(), 100);
    }
  
    componentWillUnmount() {
      clearInterval(this.intervalHandle);
    }
  
    componentDidUpdate() {
      this.updateCanvas();
    }
  
    updateSize() {
      var el = this.canvasRef.current;
  
      el.style.width = '100%';
      el.style.height = '100%';
      var width = el.offsetWidth;
      var height = el.offsetHeight;
  
      if (!height) {
        var style = window.getComputedStyle(el.parentElement, null);
        height = parseInt(style.getPropertyValue("height").replace("px", ""), 10);
      }
  
      this.setState({
        width: width,
        height: height
      })
    }
  
    updateCanvas() {
      var gs = new CollarGraphicsScene(this.props.draw, this.canvasRef.current);
      gs.disableBackground = this.props.disableBackground;
      gs.redraw();
    }
  
    get width() {
      return this.state.width || "1200";
    }
  
    get height() {
      if (this.state.height > this.props.height)
        return this.props.height;
  
      return this.state.height || "800";
    }
  
    render() {
      return <canvas ref={this.canvasRef} width={this.width} height={this.height} />
    }
  }
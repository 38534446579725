import { uniq, intersection } from "lodash";
import React from "react";
import { CollarMaterial, getLeather, getLeatherColorByName } from "../../options";
import { leatherColors, LeatherColors } from "../../settings";
import { AbstractCollar } from "../AbstractCollar";
import { AbstractCollarPricing } from "../AbstractPricing";
import { AbstractCollarDefinition } from "../BaseCollarDefinition";
import { AdjustmentHoles, CollarWidth, Decorations, Locking, NamePlate, PowderCoated, RingAttachment, SizeSlider, Stiching } from "../../components";
import { MaterialSelection } from "../../components/MaterialSelection";
import { AccentStrapCollarDraw } from "./Draw";
import { AccentStrapCollarPricing } from "./Pricing";
import { fromList, isGatorStrap } from "../../strap/AbstractStrap";


export interface AccentStrapCollarDefinition extends AbstractCollarDefinition {
  type: 'AccentStrap';
  accentMaterial: CollarMaterial | null;
  accentWidth: number;
  accentStiching: string;
}

export class AccentStrapCollar extends AbstractCollar<AccentStrapCollarDefinition> {
  name = "Primary Plus Accent Strap Collar";

  constructor(definition: AccentStrapCollarDefinition) {
    super(definition);
    this.definition = this.cleanup(this.definition);
  }

  public cleanup(input: AccentStrapCollarDefinition): AccentStrapCollarDefinition {
    let definition = {
      ...this.baseCleanup(input),
      accentMaterial: this.cleanupColorOption(input.accentMaterial, this.getAccentStrapOptions(), 'Pink (UV Reactive)'),
    };

    if (definition.DRingType === undefined) {
      definition.DRingType = this.getRingOptions()[0];
    }

    if (definition.primaryMaterial.startsWith('GatorStrap')) {
      definition.primaryStiching = null;
    }

    return {
      ...definition,
      type: 'AccentStrap',
      neckSize: input.neckSize || 16,
      holes: Math.min(input.holes || 5, this.getMaxNumberOfHoles()),

      studDistance: input.studDistance || 0,
      powderCoated: input.powderCoated || null,
      studCount: input.studCount || 0,
      rivetCount: input.rivetCount || 0,
      accentWidth: fromList(this.getAccentWidthOptions(definition.primaryWidth, definition.accentMaterial), input.accentWidth || 1),
      accentStiching: this.isAccentStichingDisabled(definition.accentMaterial) ? null : input.accentStiching || null,
    };
  }

  public getPricing(): AbstractCollarPricing<AccentStrapCollarDefinition> {
    return new AccentStrapCollarPricing();
  }

  public getPrimaryOptions(): LeatherColors[] | null {
    const latigo = getLeather('Latigo');
    const gatorStrap = getLeather('GatorStrap');
    return [gatorStrap, latigo];
  }

  public getAccentStrapOptions(): LeatherColors[] | null {
    const latigo = getLeather('Latigo');
    const gatorStrap = getLeather('GatorStrap');

    return [gatorStrap, latigo];
  }

  public getCollarWidthOptions(): number[] {
    const { definition } = this;

    if (definition.primaryMaterial?.startsWith('GatorStrap')) {
      const color = getLeatherColorByName(this.getPrimaryOptions(), definition.primaryMaterial);
      return uniq(color.sizes).filter(t => t >= 1.0);
    }

    return [1.0, 1.25, 1.5, 2.0];
  }

  public getAccentWidthOptions(primaryWidth: number, accentMaterial: string): number[] {
    let options = [0.5, 0.625, 0.75, 1, 1.25, 1.5];
    if (primaryWidth == 1) {
      options = [0.5, 0.625, 0.75];
    }
    if (primaryWidth == 1.25) {
      options = [0.5, 0.625, 0.75, 1];
    }
    if (primaryWidth == 1.5) {
      options = [0.5, 0.625, 0.75, 1, 1.25];
    }

    if (isGatorStrap(accentMaterial)) {
      const color = getLeatherColorByName(leatherColors, accentMaterial);
      return intersection(color.sizes, options);
    }

    return options;
  }

  public isNamePlateDisabled(): string | undefined {
    const { accentMaterial, primaryMaterial } = this.definition;
    if (isGatorStrap(accentMaterial) || isGatorStrap(primaryMaterial)) {
      return "Nameplace is not available with GatorStrap.";
    }

    return undefined;
  }

  public isStichingDisabled(): string | null {
    const { definition } = this;

    // TODO: Logic behind this (?) - comform with min height
    if (['padlockstaple'].includes(definition.lockingPost)) {
      return "Stiching on the strap can not exist with this locking mechanism.";
    }

    if (isGatorStrap(definition.primaryMaterial)) {
      return "Stiching is not available with GatorStrap.";
    }

    return null;
  }

  public isAccentStichingDisabled(material: CollarMaterial): string | null {
    if (isGatorStrap(material)) {
      return "Stiching is not available with GatorStrap.";
    }

    return null;
  }

  public getDrawObject(): AccentStrapCollarDraw {
    return new AccentStrapCollarDraw(this.definition);
  }

  public materialSelection(onUpdate: (def: AccentStrapCollarDefinition) => void): JSX.Element {
    const { definition } = this;
    const linerOptions = this.getLinerOptions();

    return <>
      <MaterialSelection
        title="Primary Strap"
        type={definition.primaryMaterial}
        onUpdate={(m) => onUpdate({ ...definition, primaryMaterial: m })}
        options={this.getPrimaryOptions()}
      />
      <MaterialSelection
        title="Accent Strap"
        type={definition.accentMaterial}
        onUpdate={(m) => onUpdate({ ...definition, accentMaterial: m })}
        options={this.getAccentStrapOptions()}
      />
      {linerOptions && (<MaterialSelection
        title="Inside Liner"
        type={definition.linerMaterial}
        onUpdate={(m) => onUpdate({ ...definition, linerMaterial: m })}
        options={linerOptions}
      />)}
    </>;
  }

  public optionsSelection(onUpdate: (def: AccentStrapCollarDefinition) => void): JSX.Element {
    const { definition } = this;
    const pricing = this.getPricing();
    return <>
      <div className="col-sm-6 product-options form-horizontal" style={{ padding: '10px' }}>
        <h3>Sizing</h3>
        <SizeSlider
          minSize={this.getMinStrapSize()}
          onUpdate={size => onUpdate({ ...definition, neckSize: size })}
          size={definition.neckSize}
        />
        <CollarWidth
          options={this.getCollarWidthOptions()}
          onUpdate={size => onUpdate({ ...definition, primaryWidth: size })}
          size={definition.primaryWidth}
        />
        <CollarWidth
          title='Accent Width'
          options={this.getAccentWidthOptions(definition.primaryWidth, definition.accentMaterial)}
          onUpdate={size => onUpdate({ ...definition, accentWidth: size })}
          size={definition.accentWidth}
        />
        <AdjustmentHoles
          maxHoles={this.getMaxNumberOfHoles()}
          onUpdate={size => onUpdate({ ...definition, holes: size })}
          holes={definition.holes}
        />
      </div>
      <div className="col-sm-6" style={{ padding: '10px' }}>
        <h3>Options</h3>
        <table className="table">
          <tbody>
            <RingAttachment
              onUpdate={v => onUpdate({ ...definition, DRingType: v })}
              value={definition.DRingType}
              options={this.getRingOptions()}
              price={pricing.getDRing(definition.primaryWidth, definition.DRingType || 'Front')}
            />
            <PowderCoated
              onUpdate={v => onUpdate({ ...definition, powderCoated: v })}
              value={definition.powderCoated}
              price={pricing.getPowderedCoating(this.getPowderPricing())}
            />
            <Stiching
              onUpdate={v => onUpdate({ ...definition, primaryStiching: v })}
              value={definition.primaryStiching}
              title='Add Primary Stitching'
              disabled={this.isStichingDisabled()}
              price={pricing.getStiching()}
            />
            <Stiching
              onUpdate={v => onUpdate({ ...definition, accentStiching: v })}
              value={definition.accentStiching}
              title='Add Accent Stitching'
              disabled={this.isAccentStichingDisabled(definition.accentMaterial)}
              price={pricing.getStiching()}
            />
            <Locking
              onUpdate={v => onUpdate({ ...definition, lockingPost: v })}
              value={definition.lockingPost}
              disabled={null}
              options={this.getLockingOptions(definition.primaryMaterial, definition.primaryWidth)}
              price={pricing.getLockingPost(definition.lockingPost || 'padlockpost')}
            />
            <NamePlate
              onUpdate={(n, nv) => onUpdate({ ...definition, nameTag: n, nameTagValue: nv })}
              nameTag={definition.nameTag}
              nameTagValue={definition.nameTagValue}
              price={pricing.getNameTag()}
              disabled={this.isNamePlateDisabled()}
            />
            <Decorations
              onUpdate={(sc, sd) => onUpdate({ ...definition, studCount: sc, studDistance: sd })}
              studCount={definition.studCount}
              studDistance={definition.studDistance}
            />
          </tbody>
        </table>
      </div>
    </>;
  }

  public prettyPrint() {
    const { definition } = this;
    let output = super.prettyPrint();

    output += `Accent Material: ${definition.accentMaterial}\n`;
    output += `Accent Width: ${definition.accentWidth}\n`;

    if (definition.accentStiching) {
      output += `Accent Stiching: ${definition.accentStiching}\n`;
    }

    return output;
  }
}


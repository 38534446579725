import { startsWith, uniq } from "lodash";
import React from "react";
import { getLeather, getLeatherColorByName } from "../../options";
import { LeatherColors } from "../../settings";
import { AbstractCollar, checkNameTag } from "../AbstractCollar";
import { AbstractCollarPricing } from "../AbstractPricing";
import { AbstractCollarDefinition } from "../BaseCollarDefinition";
import { AdjustmentHoles, CollarWidth, Decorations, Locking, NamePlate, PowderCoated, RingAttachment, SizeSlider, Stiching } from "../../components";
import { MaterialSelection } from "../../components/MaterialSelection";
import { PrimaryStrapCollarDraw } from "./Draw";
import { PrimaryStrapPricing } from "./Pricing";
import { isGatorStrap } from "../../strap/AbstractStrap";


export interface PrimaryStrapCollarDefinition extends AbstractCollarDefinition {
  type: 'PrimaryStrap';
}

export class PrimaryStrapCollar extends AbstractCollar<PrimaryStrapCollarDefinition> {
  name = "Primary Strap Collar";

  constructor(definition: PrimaryStrapCollarDefinition) {
    super(definition);
    this.definition = this.cleanup(this.definition);
  }

  public cleanup(input: PrimaryStrapCollarDefinition): PrimaryStrapCollarDefinition {
    let definition = { 
      ...this.baseCleanup(input),
    };

    if (definition.lockingPost) {
      if (definition.primaryWidth < 1) {
        definition.primaryStiching = null;
      }
      if (["padlockplate", "padlockstaple"].includes(definition.lockingPost)) {
        definition.primaryStiching = null;
      }
    }

    if (definition.primaryMaterial.startsWith("GatorStrap")) {
      definition.primaryStiching = null;
    }

    checkNameTag(definition);

    if(definition.primaryWidth < 0.5) {
      definition.lockingPost = null;
    }

    if (definition.DRingType && definition.primaryMaterial.startsWith("GatorStrap")) {
      definition.DRingType = 'Pet';
    }

    return {
      ...definition,
      type: 'PrimaryStrap',
      neckSize: Math.max(input.neckSize || 16, this.getMinStrapSize()),
      holes: Math.min(input.holes || 5, this.getMaxNumberOfHoles()),
      studDistance: input.studDistance || 0,
      powderCoated: input.powderCoated || null,
      studCount: input.studCount || 0,
      rivetCount: input.rivetCount || 0,
    };
  }

  public getPricing(): AbstractCollarPricing<PrimaryStrapCollarDefinition> {
    return new PrimaryStrapPricing();
  }

  public getPrimaryOptions(): LeatherColors[] | null {
    const latigo = getLeather('Latigo');
    const gatorStrap = getLeather('GatorStrap');
    return [gatorStrap, latigo];
  }

  public getCollarWidthOptions(): number[] {
    const { definition } = this;

    if (definition.primaryMaterial?.startsWith('GatorStrap')) {
      const color = getLeatherColorByName(this.getPrimaryOptions(), definition.primaryMaterial);
      return uniq(color.sizes);
    }

    const items = [0.5, 0.625, 0.75, 1.0, 1.25, 1.5, 2.0];

    if (definition.linerMaterial && (definition.linerMaterial.startsWith("Rabbit Fur") || definition.linerMaterial.startsWith("Wool"))) {
      return items.filter(t => t >= 1.0);
    }

    return items;
  }

  public getRingOptions() {
    if (startsWith(this.definition.primaryMaterial, "GatorStrap")) {
      return ['Pet'];
    }

    return super.getRingOptions();
  }

  public isNamePlateDisabled(): string | undefined {
    const { primaryMaterial } = this.definition;
    if (isGatorStrap(primaryMaterial)) {
      return "Nameplace is not available with GatorStrap.";
    }

    return undefined;
  }

  public isStichingDisabled(): string | null {
    const { definition } = this;

    if (definition.lockingPost && definition.primaryWidth < 1) {
      return "There can not be stiching and locking on a collar smaller than 1\".";
    }

    if (['padlockplate', 'padlockstaple'].includes(definition.lockingPost)) {
      return "Stiching on the strap can not exist with this locking mechanism.";
    }

    if (startsWith(definition.primaryMaterial, "GatorStrap")) {
      return "Stiching is not available with GatorStrap.";
    }

    return null;
  }

  public getDrawObject(): PrimaryStrapCollarDraw {
    return new PrimaryStrapCollarDraw(this.definition);
  }

  public materialSelection(onUpdate: (def: PrimaryStrapCollarDefinition) => void): JSX.Element {
    const { definition } = this;
    const linerOptions = this.getLinerOptions();

    return <>
      <MaterialSelection
        title="Primary Strap"
        type={definition.primaryMaterial}
        onUpdate={(m) => onUpdate({ ...definition, primaryMaterial: m })}
        options={this.getPrimaryOptions()}
      />
      {linerOptions && (<MaterialSelection
        title="Inside Liner"
        type={definition.linerMaterial}
        onUpdate={(m) => onUpdate({ ...definition, linerMaterial: m })}
        options={linerOptions}
      />)}
    </>;
  }

  public optionsSelection(onUpdate: (def: PrimaryStrapCollarDefinition) => void): JSX.Element {
    const { definition } = this;
    let pricing = this.getPricing();
    return <>
      <div className="col-sm-6 product-options form-horizontal" style={{ padding: '10px' }}>
        <h3>Sizing</h3>
        <SizeSlider
          minSize={this.getMinStrapSize()}
          onUpdate={size => onUpdate({ ...definition, neckSize: size })}
          size={definition.neckSize}
        />
        <CollarWidth
          options={this.getCollarWidthOptions()}
          onUpdate={size => onUpdate({ ...definition, primaryWidth: size })}
          size={definition.primaryWidth}
        />
        <AdjustmentHoles
          maxHoles={this.getMaxNumberOfHoles()}
          onUpdate={size => onUpdate({ ...definition, holes: size })}
          holes={definition.holes}
        />
      </div>
      <div className="col-sm-6" style={{ padding: '10px' }}>
        <h3>Options</h3>
        <table className="table">
          <tbody>
            <RingAttachment
              onUpdate={v => onUpdate({ ...definition, DRingType: v })}
              value={definition.DRingType}
              options={this.getRingOptions()}
              price={pricing.getDRing(definition.primaryWidth, definition.DRingType || 'Front')}
            />
            <PowderCoated
              onUpdate={v => onUpdate({ ...definition, powderCoated: v })}
              value={definition.powderCoated}
              price={pricing.getPowderedCoating(this.getPowderPricing())}
            />
            <Stiching
              onUpdate={v => onUpdate({ ...definition, primaryStiching: v })}
              value={definition.primaryStiching}
              title='Add Primary Stitching'
              disabled={this.isStichingDisabled()}
              price={pricing.getStiching()}
            />
            <Locking
              onUpdate={v => onUpdate({ ...definition, lockingPost: v })}
              value={definition.lockingPost}
              disabled={null}
              options={this.getLockingOptions(definition.primaryMaterial, definition.primaryWidth)}
              price={pricing.getLockingPost(definition.lockingPost || 'padlockpost')}
            />
            <NamePlate
              onUpdate={(n, nv) => onUpdate({ ...definition, nameTag: n, nameTagValue: nv })}
              nameTag={definition.nameTag}
              nameTagValue={definition.nameTagValue}
              price={pricing.getNameTag()}
              disabled={this.isNamePlateDisabled()}
            />
            <Decorations
              onUpdate={(sc, sd) => onUpdate({ ...definition, studCount: sc, studDistance: sd })}
              studCount={definition.studCount}
              studDistance={definition.studDistance}
            />
          </tbody>
        </table>
      </div>
    </>;
  }
}

let imageCache = {};

export let getImage = (url: string, width?: number, height?: number) => {
  let image = imageCache[url];

  if (!image) {
    image = new Image(width, height);
    image.src = url;
    imageCache[url] = image;
  }

  return image;
}

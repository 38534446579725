import { splitColor } from "../../strap/AbstractPricing";
import { AbstractCuffPricing, PowderCoatingPrice } from "../AbstractCuffPricing";
import { PrimaryStrapCuffDefinition } from "./Cuff";

export class PrimaryCuffPricing extends AbstractCuffPricing<PrimaryStrapCuffDefinition> {

  public getBasePrice() {
    return 20;
  }

  public getPowderedCoating(input: PowderCoatingPrice) {
    let extra = input.collarWidth < 1 ? 4 : 6;

    return extra + super.getPowderedCoating(input);
  }

  public materialCost(def: PrimaryStrapCuffDefinition) {
    const primary = splitColor(def.primaryMaterial);
    let additional = 0.0;
    
    if (primary.fabric == "Latigo") {
      const liner = splitColor(def.linerMaterial);
      if (def.primaryWidth == 0.5) {
        additional += 15.0;
      } else if (def.primaryWidth == 0.625) {
        additional += 17.0;
      } else if (def.primaryWidth == 0.75) {
        additional += 20.0;
      } else if (def.primaryWidth == 1.0) {
        additional += 25.0;

        if (liner.fabric == "Rabbit Fur") { additional += 34.0; }
        if (liner.fabric == "Wool") { additional += 44.0; }
      } else if (def.primaryWidth == 1.25) {
        additional += 50.0;

        if (liner.fabric == "Rabbit Fur") { additional += 50.0; }
        if (liner.fabric == "Wool") { additional += 60.0; }
      } else if (def.primaryWidth == 1.5) {
        additional += 70.0;

        if (liner.fabric == "Rabbit Fur") { additional += 50.0; }
        if (liner.fabric == "Wool") { additional += 60.0; }
      } else {
        additional += 90.0;

        if (liner.fabric == "Rabbit Fur") { additional += 70.0; }
        if (liner.fabric == "Wool") { additional += 80.0; }
      }
    }

    if (primary.fabric == "GatorStrap") {
      if (def.primaryWidth == 0.625) {
        additional += 0.0;
      } else if (def.primaryWidth == 1.0) {
        additional += 10.0;
      } else if (def.primaryWidth == 1.5) {
        additional += 25.0;
      } else {
        additional += 35.0;
      }
    }

    return additional;
  }
}

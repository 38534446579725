import { AbstractStrapDefinition } from "./AbstractStrapDefinition";

export interface PowderCoatingPrice {
  powderCoated: string;
  lockingPost: string;
  DRingType: string;
  rivetCount: number;
  collarWidth: number;
}

export function splitColor(name: string): { color: string; fabric: string } {
  const split = name.split('/');
  return {
    fabric: split[0],
    color: split[1],
  };
}

export abstract class AbstractStripePricing<T extends AbstractStrapDefinition> {
  public abstract getTotal(def: T): number;

  public getStiching() {
    return 20;
  }

  public getPowderedCoating(input: PowderCoatingPrice) {
    var extra = 0.0;
    const multiplier = input.collarWidth > 1.0 ? 3.0 : 1.0;

    if (input.lockingPost == "padlockpost") {
      extra += 4.0;
    } else if (input.lockingPost == "padlockplate" || input.lockingPost == "padlockstaple") {
      extra += 6.0;
    }

    // For the D-rings
    if (input.DRingType == "Front") extra += 2.0 * multiplier;
    if (input.DRingType == "Pet") extra += 2.0 * multiplier;
    if (input.DRingType == "Front+Pet") extra += 4.0 * multiplier;
    if (input.DRingType == "3 Ring") extra += 6.0 * multiplier;
    if (input.DRingType == "5 Ring") extra += 10.0 * multiplier;

    // For the rivet
    extra += input.rivetCount * 1.0;

    return extra;
  }

  public getDRing(width: number, DRingType: string) {
    let total = 0;
    if (DRingType === 'Front' || DRingType === 'Pet') {
      total += 0;
    }

    if (DRingType === 'Front+Pet') {
      total += 20;
      
      if (width >= 2.5) {
        total += 30;
      } else if (width >= 2.0) {
        total += 20;
      } else if (width >= 1.5) {
        total += 10;
      }
    }

    if (DRingType === '3 Ring') {
      total += 40;

      if (width >= 2.5) {
        total += 30 * 2;
      } else if (width >= 2.0) {
        total += 20 * 2;
      } else if (width >= 1.5) {
        total += 10 * 2;
      }
    }

    if (DRingType === '5 Ring') {
      total += 80;

      if (width >= 2.5) {
        total += 30 * 4;
      } else if (width >= 2.0) {
        total += 20 * 4;
      } else if (width >= 1.5) {
        total += 10 * 4;
      }
    }

    return total;
  }
}

import { BaseCollarObject } from "./BaseCollarObject";

export class RingDraw extends BaseCollarObject<{ size?: number; }> {
  get size() { return this.opts.size || (1.0 / 8.0); }

  drawMiddleLayer(ctx) {
    ctx.strokeStyle = "rgb(200,200,200)";
    ctx.lineWidth = 0.1;
    ctx.setLineDash([0])

    ctx.beginPath();
    ctx.arc(0, 0, this.size / 2 - ctx.lineWidth, 0, 2 * Math.PI);
    ctx.stroke();
  }
}


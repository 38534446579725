import React from 'react';
import Toggle from 'react-bootstrap-toggle';
import numeral from 'numeral';

interface ExtraBoltsProps {
  onUpdate(value: number): void;
  value: number;
  price: number;
}

export const ExtraBolts: React.FC<ExtraBoltsProps> = props => {
  const { value, onUpdate, price } = props;
  let style: React.CSSProperties = {
    verticalAlign: 'middle'
  }

  return <tr style={style}>
    <td style={{ verticalAlign: 'middle' }}>
      <Toggle onClick={v => onUpdate(v ? 2 : 0)} active={value > 0} />
    </td>
    <td>
      <div style={{ fontSize: "21px" }}>Spare set of sex bolts</div>
      <small>Ready replacements if you strip out the screw threads by tugging too hard on a leash or similar.</small>
      {value > 0 && <ExtraBoltsOptions {...props} />}
    </td>
    <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
      <h3 style={{ margin: '0px' }}>{numeral(price).format('$0,0.00')}</h3>
    </td>
  </tr>;
}

const ExtraBoltsOptions: React.FC<ExtraBoltsProps> = props => {
  const { value, onUpdate } = props;
  const options = [1, 2, 3, 4, 5, 6, 7, 8];

  return <div>
    Choose number of additional bolts:
    <select name="lockingpostSelect" value={value} onChange={e => onUpdate(parseInt(e.target.value, 10))}>
      {options.map(entry => {
        return <option key={entry} value={entry}>{entry}</option>;
      })}
    </select>
  </div>;
}

import { startsWith } from "lodash";
import React from "react";
import { getLeather } from "../../options";
import { LeatherColors } from "../../settings";
import { AbstractCollar, checkNameTag } from "../AbstractCollar";
import { AbstractCollarDefinition } from "../BaseCollarDefinition";
import { CollarWidth, Decorations, SizeSlider, Stiching } from "../../components";
import { MaterialSelection } from "../../components/MaterialSelection";
import { MartinGaleCollarDraw } from "./Draw";
import { MartinGaleCollarPricing } from "./Pricing";


export interface MartinGaleCollarDefinition extends AbstractCollarDefinition {
  type: 'MartinGaleCollar';
}

export class MartinGaleCollar extends AbstractCollar<MartinGaleCollarDefinition> {
  name = "Martingale Collar + Chain";

  constructor(definition: MartinGaleCollarDefinition) {
    super(definition);
    this.definition = this.cleanup(this.definition);
  }

  public cleanup(input: MartinGaleCollarDefinition): MartinGaleCollarDefinition {
    let definition = {
      ...this.baseCleanup(input),
    };

    if (definition.primaryWidth < 0.5) {
      definition.lockingPost = null;
    }

    if (this.isStichingDisabled(definition.primaryMaterial, definition.lockingPost, definition.primaryWidth)) {
      definition.primaryStiching = null;
    }

    checkNameTag(definition);

    if (definition.DRingType && definition.primaryMaterial.startsWith("GatorStrap")) {
      definition.DRingType = 'Pet';
    }

    return {
      ...definition,
      type: 'MartinGaleCollar',
      neckSize: Math.max(input.neckSize || 16, this.getMinStrapSize()),
      holes: Math.min(input.holes || 5, this.getMaxNumberOfHoles()),
      studDistance: input.studDistance || 0,
      powderCoated: null,
      studCount: input.studCount || 0,
      rivetCount: input.rivetCount || 0,
    };
  }

  public getPricing(): MartinGaleCollarPricing {
    return new MartinGaleCollarPricing();
  }

  public getPrimaryOptions(): LeatherColors[] | null {
    const latigo = getLeather('Latigo');
    const gatorStrap = getLeather('GatorStrap');
    return [gatorStrap, latigo];
  }

  public getCollarWidthOptions(): number[] {
    return [1];
  }

  public getRingOptions() {
    return [];
  }

  public isStichingDisabled(material: string, lockingPost: string, width: number): string | null {
    if (lockingPost && width < 1) {
      return "There can not be stiching and locking on a collar smaller than 1\".";
    }

    if (['padlockplate', 'padlockstaple'].includes(lockingPost)) {
      return "Stiching on the strap can not exist with this locking mechanism.";
    }

    if (startsWith(material, "GatorStrap")) {
      return "Stiching is not available with GatorStrap.";
    }

    return null;
  }

  public getLinerOptions(): LeatherColors[] | null {
    if (startsWith(this.definition.primaryMaterial, "GatorStrap")) {
      return null;
    }

    const deer = getLeather('Deer');
    const buffalo = getLeather('Buffalo');
    const fursuit = getLeather('FURSUIT');

    return [
      deer,
      { ...buffalo, colors: buffalo.colors.filter(c => c.name === 'black') },
      fursuit,
    ];
  }

  public getDrawObject(): MartinGaleCollarDraw {
    return new MartinGaleCollarDraw(this.definition);
  }

  public materialSelection(onUpdate: (def: MartinGaleCollarDefinition) => void): JSX.Element {
    const { definition } = this;
    const linerOptions = this.getLinerOptions();

    return <>
      <MaterialSelection
        title="Primary Strap"
        type={definition.primaryMaterial}
        onUpdate={(m) => onUpdate({ ...definition, primaryMaterial: m })}
        options={this.getPrimaryOptions()}
      />
      {linerOptions && (<MaterialSelection
        title="Inside Liner"
        type={definition.linerMaterial}
        onUpdate={(m) => onUpdate({ ...definition, linerMaterial: m })}
        options={linerOptions}
      />)}
    </>;
  }

  public optionsSelection(onUpdate: (def: MartinGaleCollarDefinition) => void): JSX.Element {
    const { definition } = this;
    const { primaryMaterial, primaryWidth, lockingPost } = definition;
    let pricing = this.getPricing();

    // TODO: Collar measurements for the "chain" part

    return <>
      <div className="col-sm-6 product-options form-horizontal" style={{ padding: '10px' }}>
        <h3>Sizing</h3>
        <SizeSlider
          minSize={this.getMinStrapSize()}
          onUpdate={size => onUpdate({ ...definition, neckSize: size })}
          size={definition.neckSize}
        />
        <CollarWidth
          options={this.getCollarWidthOptions()}
          onUpdate={size => onUpdate({ ...definition, primaryWidth: size })}
          size={definition.primaryWidth}
        />
      </div>
      <div className="col-sm-6" style={{ padding: '10px' }}>
        <h3>Options</h3>
        <table className="table">
          <tbody>
            <Stiching
              onUpdate={v => onUpdate({ ...definition, primaryStiching: v })}
              value={definition.primaryStiching}
              title='Add Stitching'
              disabled={this.isStichingDisabled(primaryMaterial, lockingPost, primaryWidth)}
              price={pricing.getStiching()}
            />
            <Decorations
              onUpdate={(sc, sd) => onUpdate({ ...definition, studCount: sc, studDistance: sd })}
              studCount={definition.studCount}
              studDistance={definition.studDistance}
            />
          </tbody>
        </table>
      </div>
    </>;
  }
}

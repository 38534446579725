import { BaseCollarStrapDraw, BuckleDraw, RivetDraw } from "../../drawing";
import { threadColors } from "../../settings";
import { AbstractCuffDraw } from "../AbstractCuffDraw";
import { PrimaryStrapCuffDefinition } from "./Cuff";


export class PrimaryCuffDraw extends AbstractCuffDraw<PrimaryStrapCuffDefinition> {

  constructor(opts: PrimaryStrapCuffDefinition) {
    super(opts);

    this.addTopStrap(this.objs);

    if (this.hasPetRing) {
      this.addDRing(this.objs, -this.strapSize / 2 + 1.5, false);
    }

    this.objs.push(new BuckleDraw({
      x: -this.strapSize / 2,
      y: 0,
      h: this.primaryWidth,
      isTopStrap: true,
      color: this.metalColor,
    }));

    this.objs.push(new BaseCollarStrapDraw({
      color: this.getLeatherColorByName(opts.primaryMaterial),
      x: (this.topStrapSize - this.strapSize) / 2,
      y: 0,
      w: this.topStrapSize,
      h: this.primaryWidth,
      isTopStrap: true,
      addRightRounded: true,
      seamColor: this.getColorByName(threadColors, opts.primaryStiching)
    }));
  }
}

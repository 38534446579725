import * as settings from './settings';
import { filter, first, startsWith, split } from 'lodash';

export type CollarMaterial = string;

declare global {
  interface Window {
    renderLagartoApp: any;
    renderCuffApp: any;
    renderCollar: any;
    renderCuff: any;
    updateCollarPreview: any;
    $: any;
  }
}

export function encodeDefinition(details: any): URLSearchParams {
  const newParams = new URLSearchParams();

  for (const [k, v] of Object.entries(details)) {
    if (v) {
      newParams.set(k, v.toString());
    }
  }

  return newParams;
}

export let DRingTypes = settings.DRingTypes;
export let threadColors = settings.threadColors;
export let nameTagOptions = settings.nameTagOptions;
export let studArray = settings.studs;

export function getLeather(name: string): settings.LeatherColors {
  return first(filter(settings.leatherColors, (t) => startsWith(name, t.name)));
}

export function getLeatherByName(leatherColors: settings.LeatherColors[], name: string): settings.LeatherColors {
  return first(filter(leatherColors, (t) => startsWith(name, t.name)));
}

export function getLeatherColorByName(leatherColors: settings.LeatherColors[], name: string): settings.LeatherColor {
  const leather = getLeatherByName(leatherColors, name);
  const parts = split(name, /-|\//);

  if (!leather)
    return null;

  let color = first(filter(leather.colors, (t) => t.name === parts[1]));

  if (!color)
    return leather.colors[0];

  return color;
}

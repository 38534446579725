import React from 'react';
import Toggle from 'react-bootstrap-toggle';
import numeral from 'numeral';
import { nameTagOptions } from '../options';


interface NamePlateProps {
  onUpdate(nameTag: string | null, nameTagValue: string | null): void;
  nameTag: string | null;
  nameTagValue: string | null;
  price: number;
  disabled?: string;
}

export const NamePlate: React.FC<NamePlateProps> = props => {
  const { nameTag, onUpdate, price, disabled } = props;

  let style: React.CSSProperties = {
    verticalAlign: 'middle'
  }

  let message = "Chrome letters strung on color matched leather strip. 1 or 2 words, 8 letters max.";

  if (disabled) {
    style.color = '#999';
    message = disabled;
  }

  return <tr style={style}>
    <td style={{ verticalAlign: 'middle' }}>
      <Toggle
        onClick={v => onUpdate(v ? nameTagOptions[0].name : null, '')}
        active={nameTag !== null}
        disabled={!!disabled}
      />
    </td>
    <td>
      <div style={{ fontSize: "21px" }}>Add Name Plate</div>
      <small>{message}</small>
      {nameTag !== null && <NamePlateOptions {...props} />}
    </td>
    <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
      <h3 style={{ margin: '0px' }}>{numeral(price).format('$0,0.00')}</h3>
    </td>
  </tr>;
};

const NamePlateOptions: React.FC<NamePlateProps> = props => {
  const { nameTag, nameTagValue, onUpdate } = props;

  return <div>
    <input
      type="text"
      placeholder="Nametag Value"
      style={{ height: '18px' }}
      value={nameTagValue || ''}
      onChange={e => onUpdate(nameTag, e.target.value)}
    />
    <select value={nameTag} onChange={e => onUpdate(e.target.value, nameTagValue)}>
      {nameTagOptions.map(opt => {
        return <option key={opt.name} value={opt.name}>{opt.name}</option>;
      })}
    </select>
  </div>;
};

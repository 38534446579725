import { AbstractStrapDefinition } from "../strap/AbstractStrapDefinition";

export const cuffLocations: string[] = [
  'Wrist Cuffs',
  'Below Elbow Cuffs',
  'Above Elbow Cuffs',
  'Bicep Cuffs',
  'Ankle Cuffs',
  'Below Knee Cuffs',
  'Above Knee Cuffs',
  'Thigh Cuffs',
];

export interface AbstractCuffDefinition extends AbstractStrapDefinition {
  sizes: number[];
}

import { isString } from "lodash";
import { AbstractStrap } from "../strap/AbstractStrap";
import { AbstractCuffDefinition, cuffLocations } from "./BaseCuffDefinition";
import { AbstractCuffPricing } from "./AbstractCuffPricing";
import React from "react";
import { CuffSlider } from "../components/CuffSlider";

function cleanupCuffSizes(sizes: number[] | string) {
  if (!sizes) {
    sizes = [];
  }

  if (isString(sizes) && sizes.includes(',')) {
    sizes = sizes.split(',').map(t => parseFloat(t));
  }

  const result = [];

  cuffLocations.map((_, index) => {
    const defaultValue = index == 0 ? 7.5 : 0;
    result[index] = sizes[index] ?? defaultValue;
  });

  return result;
}

export abstract class AbstractCuff<T extends AbstractCuffDefinition> extends AbstractStrap<T> {

  public baseCleanup(input: Readonly<T>) {
    return {
      sizes: cleanupCuffSizes(input.sizes),
      ...super.baseCleanup(input),
    };
  }

  public getMinStrapSize(): number {
    var targetMin = super.getMinStrapSize();
    return targetMin;
  }

  abstract getPricing(): AbstractCuffPricing<T>;
}

interface CuffSlidersProps {
  sizes: number[];
  minSize: number;
  onUpdate(sizes: number[]): void;
}

export const CuffSliders: React.FC<CuffSlidersProps> = props => {
  const { sizes, minSize, onUpdate } = props;

  return <>
    {cuffLocations.map((name, index) => {
      return <CuffSlider
        key={name}
        title={name}
        minSize={minSize}
        onUpdate={size => onUpdate(Object.assign([], sizes, { [index]: size }))}
        size={sizes[index]}
      />;
    })}
  </>;
};

import { BaseCollarObject } from "./BaseCollarObject";

interface RivetDrawProps {
  size?: number;
  color?: string;
  sexBolt?: boolean;
}

export class RivetDraw extends BaseCollarObject<RivetDrawProps> {
  get size() { return this.opts.size || (1.0 / 8.0); }

  drawOverlay(ctx) {
    if (this.opts.color === '#0a0a0a') {
      ctx.fillStyle = "rgb(150, 150, 150)";
      ctx.beginPath();
      ctx.arc(-0.02, -0.02, this.size, 0, 2 * Math.PI);
      ctx.fill();
    }

    ctx.fillStyle = this.opts.color || "rgb(209, 209, 209)";
    ctx.beginPath();
    ctx.arc(0, 0, this.size, 0, 2 * Math.PI);
    ctx.fill();

    if (this.opts.sexBolt) {
      ctx.strokeStyle = "rgb(120,120,120)";
      ctx.lineWidth = 0.02;
      ctx.setLineDash([0])
      let r = this.size * Math.sin(Math.PI / 4);
      this.drawLine(ctx, -r, -r, r, r);

      ctx.strokeStyle = "rgb(80,80,80)";
      ctx.lineWidth = 0.03;
      r = r * 0.33;
      this.drawLine(ctx, -r, -r, r, r);
      this.drawLine(ctx, r, -r, -r, r);
    }
  }
}

import React from "react";
import Toggle from 'react-bootstrap-toggle';
import numeral from 'numeral';

interface RingAttachmentProps {
  value: string;
  options: string[];
  price: number;
  onUpdate(value: string | null);
}

export const RingAttachment: React.FC<RingAttachmentProps> = props => {
  const { onUpdate, value, options, price } = props;

  if (options.length === 0) {
    return <></>;
  }

  return <tr>
    <td style={{ verticalAlign: 'middle' }}>
      <Toggle onClick={v => onUpdate(v ? options[0] : null)} active={value !== null} />
    </td>
    <td>
      <div style={{ fontSize: "21px" }}>Add D-Ring Attachment</div>
      <small>Adds a stylish D-ring in the front of the collar for leashses and tags.</small>
      {value && <RingOptions {...props} />}
    </td>
    <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
      <h3 style={{ margin: '0px' }}>{numeral(price).format('$0,0.00')}</h3>
    </td>
  </tr>;
}

export const RingOptions: React.FC<RingAttachmentProps> = props => {
  const { onUpdate, options, value } = props;

  return <div>
    Choose D-Ring style:
    <select name="dringOptionSelect" value={value} onChange={e => onUpdate(e.target.value)}>
      {options.map((entry) => {
        return (<option key={entry} value={entry}>{entry}</option>);
      })}
    </select>
  </div>;
}

import { AbstractCuff } from './AbstractCuff';
import { PrimaryStrapCuff } from './PrimaryStrap';

export * from './BaseCuffDefinition';

export const cuffList = {
    "PrimaryCuff": PrimaryStrapCuff,
  };
  
  export function getCuffFromJson(def: any): AbstractCuff<any> {
    if (def.type === 'PrimaryCuff') {
      return new PrimaryStrapCuff(def);
    }
  
    return new PrimaryStrapCuff(def);
  }
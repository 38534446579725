import { BaseCollarStrapDraw, BuckleDraw, RivetDraw } from "../../drawing";
import { threadColors } from "../../settings";
import { AbstractCollarDraw } from "../AbstractDraw";
import { PrimaryStrapCollar, PrimaryStrapCollarDefinition } from "./Collar";


export class PrimaryStrapCollarDraw extends AbstractCollarDraw<PrimaryStrapCollarDefinition> {

  constructor(opts: PrimaryStrapCollarDefinition) {
    super(opts);

    this.addTopStrap(this.objs);

    if (opts.studDistance > 0) {
      let leftPad = this.leftRivetPosition - 0.5;
      let rightPad = this.rightRivetPosition;
      this.addStuds(this.objs, leftPad, rightPad, 0.0);
    }

    if (this.hasPetRing) {
      this.addDRing(this.objs, -this.strapSize / 2 + 1.5, false);
    }

    this.objs.push(new BuckleDraw({
      x: -this.strapSize / 2,
      y: 0,
      h: this.primaryWidth,
      isTopStrap: true,
      color: this.metalColor,
    }));

    this.objs.push(new BaseCollarStrapDraw({
      color: this.getLeatherColorByName(opts.primaryMaterial),
      x: (this.topStrapSize - this.strapSize) / 2,
      y: 0,
      w: this.topStrapSize,
      h: this.primaryWidth,
      isTopStrap: true,
      addRightRounded: true,
      seamColor: this.getColorByName(threadColors, opts.primaryStiching)
    }));
  }
}

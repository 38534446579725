import { nameTagOptions } from "../options";
import { BaseCollarObject } from "./BaseCollarObject";
import { getImage } from "./image";


export class NameTagDraw extends BaseCollarObject<{ type: string; }> {
  public height: number;

  constructor(opts) {
    super(opts);
    this.height = 0.5625;
  }

  get size() { return 2.8; }
  get data() {
    let lst = nameTagOptions.filter(t => t.name === this.opts.type);
    return lst[0];
  }

  drawOverlay(ctx) {

    let data = this.data;

    if (!data) {
      return;
    }

    let image = getImage(data.image, data.width, data.height);
    ctx.drawImage(image, 0, 0, image.width, image.height, 0, -this.height / 2, this.size, this.height);

    /*
    ctx.fillStyle = "rgb(209, 209, 209)";

    this.pathRoundedRect(ctx, 0, -this.height / 2, this.size, this.height, 1 / 4);
    ctx.fill();

    var scale = 20;

    ctx.save();
    ctx.scale(1 / scale, 1 / scale);
    ctx.fillStyle = 'rgb(0,0,0)';
    ctx.font = '10px helvetica';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText("Name", this.size / 2 * scale, 0);
    ctx.restore();

    ctx.fillStyle = "rgb(150, 150, 150)";
    ctx.beginPath();
    ctx.arc(0.25, 0, 0.1, 0, 2 * Math.PI);
    ctx.fill();

    ctx.beginPath();
    ctx.arc(this.size - 0.25, 0, 0.1, 0, 2 * Math.PI);
    ctx.fill();
    */

  }
}
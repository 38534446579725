import { range } from "lodash";
import React, { useEffect, useRef, useState } from "react";

interface BootstrapSliderProps {
  minSize: number;
  maxSize: number;
  size: number;
  ticks?: number[];
  ticksLabels?: string[];
  onUpdate(value: number): void;
  formatter?(value: number): string;
  tooltip?: string;
  step?: number;
}

function updateSliderProp(slider, name, newValue) {
  if (parseInt(slider.getAttribute(name), 10) !== newValue) {
    slider.setAttribute(name, newValue);
    slider.refresh();
  }
}

function updateSlider(slider, newValue) {
  if (parseInt(slider.getValue(), 10) !== newValue && !slider._state.inDrag) {
    slider.setValue(newValue);
  }
}

export const BootstrapSlider: React.FC<BootstrapSliderProps> = props => {
  const { formatter, minSize, maxSize, size, onUpdate, tooltip, step } = props;

  const sliderInput = useRef(null);
  const [slider, setSlider] = useState<any>(null);
  const [value, setValue] = useState<number>(size);

  useEffect(() => {
    const slider = window.$(sliderInput.current).bootstrapSlider({
      formatter,
      tooltip,
      ticks: props.ticks ? range(props.ticks.length) : undefined,
      ticks_labels: props.ticksLabels,
    }).on('change', () => {
      let newValue = slider.getValue();
      if (props.ticks) {
        newValue = props.ticks[newValue];
      }
      setValue(newValue);
    }).data('bootstrapSlider');

    setSlider(slider);
    return () => slider.destroy();
  }, []);

  useEffect(() => {
    onUpdate(value);
  }, [value]);

  if (slider) {
    updateSliderProp(slider, 'min', minSize);
    updateSliderProp(slider, 'max', maxSize);

    if (props.ticks) {
      updateSlider(slider, props.ticks.indexOf(size));
    } else {
      updateSlider(slider, size);
    }
  }

  useEffect(() => {
    if (slider && props.ticksLabels) {
      slider.setAttribute("ticks", range(props.ticks.length));
      slider.setAttribute("ticks_labels", props.ticksLabels);
      slider.refresh({ useCurrentValue: true });
    }
  }, [props.ticksLabels]);

  return <input
    ref={sliderInput}
    style={{ width: '100%' }}
    id="sizeSlider"
    data-slider-id='ex1Slider'
    type="text"
    data-slider-min={minSize}
    data-slider-max={maxSize}
    data-slider-step={step || 1}
    data-slider-value={size}
  />;
}

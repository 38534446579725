import React from 'react';
import Toggle from 'react-bootstrap-toggle';
import numeral from 'numeral';

export interface LockingPost {
  id: string;
  name: string;
}

export const lockingPosts: LockingPost[] = [
  {
    id: "padlockpost",
    name: "Padlock Post",
  },
  {
    id: "padlockplate",
    name: "Four Rivet Padlock",
  },
  {
    id: "padlockstaple",
    name: "Padlock Staple",
  },
];

export function getLockById(id: string) {
  return lockingPosts.find(t => t.id === id);
}


interface LockingProps {
  onUpdate(value: string | null): void;
  value: string | null;
  options: LockingPost[];
  disabled: string | null;
  price: number;
}

export const Locking: React.FC<LockingProps> = props => {
  const { value, onUpdate, disabled, options, price } = props;
  let style: React.CSSProperties = {
    verticalAlign: 'middle'
  }

  let message = "Wearer can not take off locked collar without a key. Includes small chrome padlock.";

  if (disabled) {
    style.color = '#999';
    message = disabled;
  }

  return <tr style={style}>
    <td style={{ verticalAlign: 'middle' }}>
      <Toggle
        onClick={v => onUpdate(v ? options[0].id : null)}
        active={!!value}
        disabled={!!disabled}
      />
    </td>
    <td>
      <div style={{ fontSize: "21px" }}>Add locking</div>
      <small>{message}</small>
      {value !== null && <LockingOptions {...props} />}
    </td>
    <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
      <h3 style={{ margin: '0px' }}>{numeral(price).format('$0,0.00')}</h3>
    </td>
  </tr>;
}

const LockingOptions: React.FC<LockingProps> = props => {
  const { value, onUpdate, options } = props;

  return <div>
    Choose locking post style:
    <select name="lockingpostSelect" value={value} onChange={e => onUpdate(e.target.value)}>
      {options.map(entry => {
        return <option key={entry.name} value={entry.id}>{entry.name}</option>;
      })}
    </select>
  </div>;
}

import React from 'react';
import ReactDOM from 'react-dom';
import { CollarApp } from './CollarApp';
import './index.css';
import { startsWith, trim } from 'lodash';
import { CollarCanvasBase } from './Canvas';
import { getCollarFromJson } from './collars';
import { encodeDefinition } from './options';
import { CuffApp } from './CuffApp';

window.renderLagartoApp = function (elem, params) {
  ReactDOM.render(
    <CollarApp {...params} />,
    elem
  );
}

window.renderCuffApp = function (elem, params) {
  ReactDOM.render(
    <CuffApp {...params} />,
    elem
  );
}

window.renderCollar = function (elem, width, height, details, link) {
  const collar = getCollarFromJson(details);
  ReactDOM.render(
    <a href={link}>
      <CollarCanvasBase width={width} height={height} draw={collar.getDrawObject()} disableBackground={true} />
    </a>,
    elem
  );
}

window.renderCuff = function (elem, width, height, details, link) {
  // <CuffCanvas width={width} height={height} collar={details} disableBackground={true} />
  ReactDOM.render(
    <a href={link}>
    </a>,
    elem
  );
}

window.updateCollarPreview = function () {

  var elems = document.querySelectorAll(".text-left");

  elems.forEach((elem) => {
    try {
      var input = elem.querySelector("small");

      if (!input)
        return;

      var inner = input.innerText;

      if (!startsWith(inner, "JsonData"))
        return;

      var value = trim(inner.substring(inner.indexOf("{")));
      var link = elem.querySelector("a").href;

      link += "&" + encodeDefinition(JSON.parse(value)).toString();

      if (value) {
        let json = JSON.parse(value);

        if (json.collar === "Cuff") {
          window.renderCuff(elem, "120", "40", json, link);
        } else {
          window.renderCollar(elem, "120", "40", json, link);
        }
      }
    } catch(e){
      
    }
  });
}

window.$(function () {
  setInterval(() => {
    window.updateCollarPreview();
  }, 100);
})

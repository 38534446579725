import { intersection, startsWith, uniq } from "lodash";
import React from "react";
import { CollarMaterial, getLeather, getLeatherColorByName } from "../../options";
import { leatherColors, LeatherColors } from "../../settings";
import { AbstractCollar } from "../AbstractCollar";
import { AbstractCollarPricing } from "../AbstractPricing";
import { AbstractCollarDefinition } from "../BaseCollarDefinition";
import { AdjustmentHoles, CollarWidth, Decorations, Locking, NamePlate, PowderCoated, RingAttachment, SizeSlider, Stiching } from "../../components";
import { MaterialSelection } from "../../components/MaterialSelection";
import { PrimaryPlusPadCollarDraw } from "./Draw";
import { PrimaryPlusPadCollarPricing } from "./Pricing";
import { fromList, isGatorStrap } from "../../strap/AbstractStrap";


export interface PrimaryPlusPadCollarDefinition extends AbstractCollarDefinition {
  type: 'PrimaryPlusPadStrap';
  padMaterial: CollarMaterial | null;
  padStiching: string | null;
  padWidth: number;
}

export class PrimaryPlusPadCollar extends AbstractCollar<PrimaryPlusPadCollarDefinition> {
  name = "Primary Plus Pad Strap Collar";

  constructor(definition: PrimaryPlusPadCollarDefinition) {
    super(definition);
    this.definition = this.cleanup(this.definition);
  }

  public cleanup(input: PrimaryPlusPadCollarDefinition): PrimaryPlusPadCollarDefinition {
    const primaryMaterial = this.cleanupColorOption(input.primaryMaterial, this.getPrimaryOptions(), 'Pink (UV Reactive)');
    let definition = {
      ...this.baseCleanup(input),
      primaryMaterial,
      padMaterial: this.cleanupColorOption(input.padMaterial, this.getPadOptions(primaryMaterial)),
    };


    if (definition.primaryMaterial.startsWith("GatorStrap")) {

    }

    if (definition.DRingType === undefined) {
      definition.DRingType = this.getRingOptions()[0];
    }

    return {
      ...definition,
      type: 'PrimaryPlusPadStrap',
      neckSize: input.neckSize || 16,
      holes: Math.min(input.holes || 5, this.getMaxNumberOfHoles()),
      studDistance: input.studDistance || 0,
      powderCoated: input.powderCoated || null,
      studCount: input.studCount || 0,
      rivetCount: input.rivetCount || 0,
      padWidth: fromList(this.getPadWidthOptions(definition.primaryWidth, definition.padMaterial), input.padWidth || 1),
      primaryStiching: this.isStichingDisabled(definition.primaryMaterial) ? null : (definition.primaryStiching || null),
      padStiching: this.isStichingDisabled(definition.padMaterial) ? null : (input.padStiching || null),
    };
  }

  public getPricing(): AbstractCollarPricing<PrimaryPlusPadCollarDefinition> {
    return new PrimaryPlusPadCollarPricing();
  }

  public getPrimaryOptions(): LeatherColors[] | null {
    const latigo = getLeather('Latigo');
    const gatorStrap = getLeather('GatorStrap');
    return [gatorStrap, latigo];
  }

  public getPadOptions(primaryMaterial: CollarMaterial): LeatherColors[] | null {
    let gatorStrap = getLeather('GatorStrap');
    const bullhide = getLeather('Bullhide');
    const buffalo = getLeather('Buffalo');

    gatorStrap = {
      ...gatorStrap,
      colors: gatorStrap.colors.filter(t => t.sizes.some(t => t > 1)),
    }

    if (!startsWith(primaryMaterial, "GatorStrap")) {
      return [buffalo, bullhide];
    }

    return [gatorStrap, buffalo, bullhide];
  }

  public getCollarWidthOptions(): number[] {
    const { primaryMaterial, padMaterial } = this.definition;

    if (isGatorStrap(primaryMaterial)) {
      const color = getLeatherColorByName(this.getPrimaryOptions(), primaryMaterial);
      return uniq(color.sizes).filter(t => t >= 5/8 && t < 2);
    }

    if (isGatorStrap(padMaterial)) {
      return [5/8, 1, 1.25, 1.5];
    }

    return [5/8, 1, 1.25, 1.5, 2, 2.5];
  }

  public getPadWidthOptions(primaryWidth: number, padMaterial: string): number[] {
    let options = [1, 1.5, 1.75, 2.0, 2.25, 2.5];

    if(primaryWidth === 1) {
      options = [1.5, 1.75, 2.0, 2.25, 2.5]
    } else if (primaryWidth === 1.25) {
      options = [1.5, 1.75, 2.0, 2.25, 2.5, 3];
    } else if (primaryWidth === 1.5) {
      options = [2.0, 2.25, 2.5, 3, 3.5];
    } else if (primaryWidth === 2) {
      options = [2.5, 3, 3.5, 4.5];
    } else if (primaryWidth === 2.5) {
      options = [3, 3.5, 4.5, 5];
    }

    if (isGatorStrap(padMaterial)) {
      const color = getLeatherColorByName(leatherColors, padMaterial);
      return intersection(color.sizes, options);
    }

    return options;
  }

  public isStichingDisabled(material: CollarMaterial): string | null {
    const { definition } = this;

    // TODO: Logic behind this (?)
    // if (['padlockplate', 'padlockstaple'].includes(definition.lockingPost)) {
    //   return "Stiching on the strap can not exist with this locking mechanism.";
    // }

    if (startsWith(material, "GatorStrap")) {
      return "Stiching is not available with GatorStrap.";
    }

    return null;
  }

  public getDrawObject(): PrimaryPlusPadCollarDraw {
    return new PrimaryPlusPadCollarDraw(this.definition);
  }

  public getLinerOptions(): LeatherColors[] | null {
    if (startsWith(this.definition.padMaterial, "GatorStrap")) {
      return null;
    }

    const deer = getLeather('Deer');
    const buffalo = getLeather('Buffalo');
    const rabbitFur = getLeather('Rabbit Fur');
    const fursuit = getLeather('FURSUIT');

    return [
      deer,
      { ...buffalo, colors: buffalo.colors.filter(c => c.name === 'black') },
      rabbitFur,
      fursuit,
    ];
  }

  public materialSelection(onUpdate: (def: PrimaryPlusPadCollarDefinition) => void): JSX.Element {
    const { definition } = this;
    const linerOptions = this.getLinerOptions();

    return <>
      <MaterialSelection
        title="Primary Strap"
        type={definition.primaryMaterial}
        onUpdate={(m) => onUpdate({ ...definition, primaryMaterial: m })}
        options={this.getPrimaryOptions()}
      />
      <MaterialSelection
        title="Pad Strap"
        type={definition.padMaterial}
        onUpdate={(m) => onUpdate({ ...definition, padMaterial: m })}
        options={this.getPadOptions(definition.primaryMaterial)}
      />
      {linerOptions && (<MaterialSelection
        title="Inside Liner"
        type={definition.linerMaterial}
        onUpdate={(m) => onUpdate({ ...definition, linerMaterial: m })}
        options={linerOptions}
      />)}
    </>;
  }

  public optionsSelection(onUpdate: (def: PrimaryPlusPadCollarDefinition) => void): JSX.Element {
    const { definition } = this;
    const pricing = this.getPricing();

    return <>
      <div className="col-sm-6 product-options form-horizontal" style={{ padding: '10px' }}>
        <h3>Sizing</h3>
        <SizeSlider
          minSize={this.getMinStrapSize()}
          onUpdate={size => onUpdate({ ...definition, neckSize: size })}
          size={definition.neckSize}
        />
        <CollarWidth
          options={this.getCollarWidthOptions()}
          onUpdate={size => onUpdate({ ...definition, primaryWidth: size })}
          size={definition.primaryWidth}
          title='Primary strap Width'
        />
        <CollarWidth
          options={this.getPadWidthOptions(definition.primaryWidth, definition.padMaterial)}
          onUpdate={size => onUpdate({ ...definition, padWidth: size })}
          title='Pad Width'
          size={definition.padWidth}
        />
        <AdjustmentHoles
          maxHoles={this.getMaxNumberOfHoles()}
          onUpdate={size => onUpdate({ ...definition, holes: size })}
          holes={definition.holes}
        />
      </div>
      <div className="col-sm-6" style={{ padding: '10px' }}>
        <h3>Options</h3>
        <table className="table">
          <tbody>
            <RingAttachment
              onUpdate={v => onUpdate({ ...definition, DRingType: v })}
              value={definition.DRingType}
              options={this.getRingOptions()}
              price={pricing.getDRing(definition.primaryWidth, definition.DRingType || 'Front')}
            />
            <PowderCoated
              onUpdate={v => onUpdate({ ...definition, powderCoated: v })}
              value={definition.powderCoated}
              price={pricing.getPowderedCoating(this.getPowderPricing())}
            />
            <Stiching
              onUpdate={v => onUpdate({ ...definition, primaryStiching: v })}
              value={definition.primaryStiching}
              title='Add Primary Stitching'
              disabled={this.isStichingDisabled(definition.primaryMaterial)}
              price={pricing.getStiching()}
            />
            <Stiching
              onUpdate={v => onUpdate({ ...definition, padStiching: v })}
              value={definition.padStiching}
              title='Add Pad Stitching'
              disabled={this.isStichingDisabled(definition.padMaterial)}
              price={pricing.getStiching()}
            />
            <Locking
              onUpdate={v => onUpdate({ ...definition, lockingPost: v })}
              value={definition.lockingPost}
              disabled={null}
              options={this.getLockingOptions(definition.primaryMaterial, definition.primaryWidth)}
              price={pricing.getLockingPost(definition.lockingPost || 'padlockpost')}
            />
            <NamePlate
              onUpdate={(n, nv) => onUpdate({ ...definition, nameTag: n, nameTagValue: nv })}
              nameTag={definition.nameTag}
              nameTagValue={definition.nameTagValue}
              price={pricing.getNameTag()}
            />
            <Decorations
              onUpdate={(sc, sd) => onUpdate({ ...definition, studCount: sc, studDistance: sd })}
              studCount={definition.studCount}
              studDistance={definition.studDistance}
            />
          </tbody>
        </table>
      </div>
    </>;
  }

  public prettyPrint() {
    const { definition } = this;
    let output = super.prettyPrint();

    output += `Pad Material: ${definition.padMaterial}\n`;
    output += `Pad Width: ${definition.padWidth}\n`;

    if (definition.padStiching != null) {
      output += `Pad Stiching: ${definition.padStiching}\n`;
    }

    return output;
  }
}


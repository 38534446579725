
//Make buckle 3/4 of an inch 
//TODO: Keep ratio on w/h 

import { BaseCollarObject } from "./BaseCollarObject";

interface BuckleDrawProps { 
  h: number; 
  isTopStrap: boolean;
  color?: string;
}

export class BuckleDraw extends BaseCollarObject<BuckleDrawProps> {
  get h() { return this.opts.h; }

  drawBack(ctx) {
    if (this.opts.isTopStrap) return;

    ctx.strokeStyle = this.opts.color || "rgb(200,200,200)";
    ctx.lineWidth = 1 / 10;
    ctx.strokeRect(- 1 / 2, -this.h / 2 - 1 / 10, 1 / 2 + 1 / 10, this.h + 2 / 10);
  }

  drawMiddleLayer(ctx) {
    if (!this.opts.isTopStrap) {
      ctx.fillStyle = this.opts.color || "rgb(200,200,200)";
      ctx.fillRect(- 1 / 2 + 0.1, -1 / 20, 1 / 2 + 1 / 10, 1 / 10);
    }
    else {
      ctx.strokeStyle = this.opts.color || "rgb(200,200,200)";
      ctx.lineWidth = 1 / 10;
      ctx.strokeRect(- 1 / 2, -this.h / 2 - 1 / 10, 1 / 2 + 1 / 10, this.h + 2 / 10);
    }
  }

  drawOverlay(ctx) {
    if (!this.opts.isTopStrap) return;

    ctx.fillStyle = this.opts.color || "rgb(200,200,200)";
    ctx.fillRect(- 1 / 2 + 0.1, -1 / 20, 1 / 2 + 1 / 10, 1 / 10);
  }
}

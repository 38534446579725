import { AbstractStripePricing, PowderCoatingPrice, splitColor } from "../strap/AbstractPricing";
import { AbstractCuffDefinition } from "./BaseCuffDefinition";

export type { PowderCoatingPrice } from '../strap/AbstractPricing'

export abstract class AbstractCuffPricing<T extends AbstractCuffDefinition> extends AbstractStripePricing<T>{

  public abstract materialCost(def: T);
  public abstract getBasePrice();

  public getTotal(def: T): number {
    let total = this.getBasePrice();

    total += this.materialCost(def);
    total += this.getDRing(def.primaryWidth, def.DRingType);
    total += this.getLockingPost(def.lockingPost);

    if (def.primaryStiching) {
      total += this.getStiching();
    }

    if (def.linerMaterial) {
      // total += this.getLinerPricing(def.primaryWidth, def.neckSize, def.linerMaterial);
    }

    if (def.powderCoated) {
      total += this.getPowderedCoating({
        DRingType: def.DRingType,
        collarWidth: def.primaryWidth,
        lockingPost: def.lockingPost,
        powderCoated: def.powderCoated,
        rivetCount: def.rivetCount,
      });
    }

    return total;
  }

  public getLinerPricing(width: number, neckSize: number, material: string) {
    var linerColor = splitColor(material);

    // Charge for every additional 0.25" with neck sizes bigger than 17" 
    var additionalNeckSize = (neckSize - 17) / 0.25;

    if (additionalNeckSize <= 0) {
      additionalNeckSize = 0;
    }

    if (width < 2.0) {
      if (linerColor.fabric == "Wool") {
        return additionalNeckSize * 1.0;
      }
      if (linerColor.fabric == "Rabbit Fur") {
        return additionalNeckSize * 1.0;
      }
      if (linerColor.fabric == "Deer") {
        return additionalNeckSize * 0.75;
      }
      if (linerColor.fabric == "Buffalo") {
        return additionalNeckSize * 0.75;
      }
      if (linerColor.fabric == "FURSUIT") {
        return additionalNeckSize * 0.75;
      }
    }

    // Width >= 2"
    if (linerColor.fabric == "Wool") {
      return additionalNeckSize * 1.25;
    }
    if (linerColor.fabric == "Rabbit Fur") {
      return additionalNeckSize * 1.25;
    }
    if (linerColor.fabric == "Deer") {
      return additionalNeckSize * 1.0;
    }
    if (linerColor.fabric == "Buffalo") {
      return additionalNeckSize * 1.0;
    }
    if (linerColor.fabric == "FURSUIT") {
      return additionalNeckSize * 1.0;
    }

    return 999.0;
  }

  public getLockingPost(lockingPost: string) {
    if (lockingPost === 'padlockpost') {
      return 23;
    }

    if (lockingPost === 'padlockplate') {
      return 28;
    }

    if (lockingPost === 'padlockstaple') {
      return 38;
    }
    return 0;
  }
}

import { BaseCollarObject } from "./BaseCollarObject";

interface LockingPostDrawProps {
  type: string; 
  size?: number; 
  color?: string;
}

export class LockingPostDraw extends BaseCollarObject<LockingPostDrawProps> {
  columnsSpace: number;

  constructor(opts) {
    super(opts);

    this.columnsSpace = 28 / 64;
  }

  get size() { return this.opts.size || (1.0 / 7.0); }

  get color() { return this.opts.color || 'rgb(130, 130, 130)'; }

  drawOverlay(ctx) {

    if (this.opts.type === "padlockpost") {
      ctx.fillStyle = this.color;
      ctx.beginPath();
      ctx.arc(0, 0, this.size + 6 / 64, 0, 2 * Math.PI);
      ctx.fill();
    } else if (this.opts.type === "padlockplate") {
      ctx.fillStyle = this.color;
      ctx.beginPath();
      ctx.fillRect(-1 / 2, -1 / 2, 1, 1);
      ctx.fill();

      const dist = 1 / 2 - 1 / 8 - 6 / 64;
      this.drawRivet(ctx, -dist, -dist);
      this.drawRivet(ctx, dist, -dist);
      this.drawRivet(ctx, -dist, dist);
      this.drawRivet(ctx, dist, dist);
    } else {
      ctx.fillStyle = this.color;
      ctx.beginPath();
      ctx.fillRect(-1 / 2, -1 / 2, 1, 1);
      ctx.fill();

      const dist = 1 / 2 - 1 / 8 - 6 / 64;
      this.drawRivet(ctx, -dist, -dist);
      this.drawRivet(ctx, dist, -dist);
      this.drawRivet(ctx, -dist, dist);
      this.drawRivet(ctx, dist, dist);
    }

    if (this.opts.type === "padlockpost" || this.opts.type === "padlockplate") {
      this.drawLock(ctx, 0, 4 / 64);

      ctx.fillStyle = "rgb(209, 209, 209)";
      ctx.beginPath();
      ctx.arc(0, 0, this.size, 0, 2 * Math.PI);
      ctx.fill();
    }
    else {
      this.drawLock(ctx, -this.columnsSpace / 2, 24 / 64);
      var height = 4;

      ctx.fillStyle = "rgb(209, 209, 209)";
      ctx.beginPath();
      ctx.fillRect(-this.size / 2, -this.size / 2 * height, this.size, this.size * height);
      ctx.fill();
    }

  }

  drawLock(ctx, x, y) {
    ctx.save();
    ctx.strokeStyle = "rgb(229, 229, 229)";
    ctx.translate(x, y);

    ctx.beginPath();
    ctx.moveTo(0, 12 / 64);
    ctx.lineWidth = 3 / 32;

    ctx.lineTo(0, -12 / 64);
    ctx.arc(this.columnsSpace / 2, -12 / 64, this.columnsSpace / 2, Math.PI, 0);
    ctx.lineTo(this.columnsSpace, -12 / 64);

    ctx.lineTo(this.columnsSpace, 12 / 64);
    ctx.stroke();

    ctx.fillStyle = "rgb(238, 224, 185)"
    ctx.fillRect(-(40 / 64) / 2 + this.columnsSpace / 2, 10 / 64, 40 / 64, 44 / 64);

    ctx.restore();
  }

  drawRivet(ctx: CanvasRenderingContext2D, x: number, y: number, size?: number) {
    if (!size) {
      size = this.size;
    }

    ctx.fillStyle = "rgb(190, 190, 190)";
    ctx.beginPath();
    ctx.arc(x, y, 1.0 / 8.0, 0, 2 * Math.PI);
    ctx.fill();
  }
}


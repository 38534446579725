import React from 'react';
import { encodeDefinition } from './options';

interface BaseAppProps {
  options: any[];
  product_id: number;
}

interface BaseAppState<T> {
  definition: T;
  selectedTab: string;
  price: number;
}

function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

export class BaseApp<T> extends React.Component<BaseAppProps, BaseAppState<T>> {

  constructor(props) {
    super(props);

    const details: any = {};
    const params = new URLSearchParams(window.location.search);

    params.forEach(function (value, key) {
      details[key] = isNumeric(value) ? parseFloat(value) : value; 
    });

    this.clickTab = this.clickTab.bind(this);
    this.addToCart = this.addToCart.bind(this);

    if(!details.type) {
      details.DRingType = 'Front';
    }

    this.state = {
      selectedTab: 'style',
      price: 0,
      definition: details as any,
    }
  }

  componentDidUpdate() {
    const params = new URLSearchParams(window.location.search);
    const newParams = encodeDefinition(this.state.definition);

    newParams.set('route', params.get('route'));
    newParams.set('product_id', params.get('product_id'));

    window.history.pushState(undefined, undefined, window.location.pathname + '?' + newParams.toString());
  }

  totalPrice(details: T) {
    return 0;
  }

  getTabs() {
    return [];
  }

  clickTab(id: string) {
    this.setState({
      selectedTab: id
    })
  }

  getMoveButtons() {
    const tabs = this.getTabs();
    const currentId = tabs.map(t => t.id).indexOf(this.state.selectedTab);
    let prev = <></>;
    let next = <></>;

    if (currentId > 0) {
      const tab = tabs[currentId - 1];
      prev = (<span>
        <button onClick={() => this.clickTab(tab.id)}>&lt; {tab.name}</button>
      </span>);
    }

    if (currentId < tabs.length - 1) {
      const tab = tabs[currentId + 1];
      next = (<span style={{ float: 'right' }}>
        <button onClick={() => this.clickTab(tab.id)}>{tab.name} &gt;</button>
      </span>);
    }

    return (<div className="col-md-12">
      <div className="col-md-6">
        {prev}
      </div>
      <div className="col-md-6" >
        {next}
      </div>
    </div>);
  }

  getProgressElement() {
    const tabs = this.getTabs();

    var progressList = tabs.map((item) => {
      var className = item.id === this.state.selectedTab ? "progress-done progress-current" : "progress-todo";

      return (<li key={item.id} className={className} onClick={() => this.clickTab(item.id)}>
        <div style={{ textAlign: 'center' }}>
          <div className="icon-wrap">
            <svg className="icon-state icon-check-mark">
              <use href="#icon-check-mark"></use>
            </svg>

            <svg className="icon-state icon-down-arrow">
              <use href="#icon-down-arrow"></use>
            </svg>
          </div>
          <span className="progress-text">{item.name}</span>
        </div>
      </li>);
    });

    return <>{progressList}</>;
  }

  getBody() {
    const tabs = this.getTabs();
    const selected = tabs.filter(t => t.id === this.state.selectedTab);

    if (selected.length > 0)
      return selected[0].render();

    return (<span>No selected body.</span>)
  }

  componentDidMount() {
    window.$('#button-cart').on('click', () => {
      this.addToCart();
    });
  }

  addToCart() {
    if (this.totalPrice(this.state.definition) === 0.0)
      return;

    var data = {
      option: {
        [this.props.options[0].product_option_id]: JSON.stringify(this.state.definition)
      },
      product_id: this.props.product_id
    };

    var $ = window.$;
    $.ajax({
      url: 'index.php?route=checkout/cart/add',
      type: 'post',
      data: data,
      dataType: 'json',
      beforeSend: function () {
        $('#button-cart').button('loading');
      },
      complete: function () {
        $('#button-cart').button('reset');
      },
      success: function (json) {
        $('.alert, .text-danger').remove();
        $('.form-group').removeClass('has-error');

        if (json['error']) {
          if (json['error']['option']) {
            var i;
            for (i in json['error']['option']) {
              var element = $('#input-option' + i.replace('_', '-'));

              if (element.parent().hasClass('input-group')) {
                element.parent().after('<div class="text-danger">' + json['error']['option'][i] + '</div>');
              } else {
                element.after('<div class="text-danger">' + json['error']['option'][i] + '</div>');
              }
            }
          }

          if (json['error']['recurring']) {
            $('select[name=\'recurring_id\']').after('<div class="text-danger">' + json['error']['recurring'] + '</div>');
          }

          // Highlight any found errors
          $('.text-danger').parent().addClass('has-error');
        }

        if (json['success']) {
          console.log("Total: " + json['total'], json);
          //$('.breadcrumb').after('<div class="alert alert-success">' + json['success'] + '<button type="button" class="close" data-dismiss="alert">&times;</button></div>');

          $('#cart > button').html('<span id="cart-total"><i class="fa fa-shopping-cart"></i> ' + json['total'] + '</span>');
          $('html, body').animate({ scrollTop: 0 }, 'slow');
          $('#cart > ul').load('index.php?route=common/cart/info ul li');
        }
      },
      error: function (xhr, ajaxOptions, thrownError) {
        alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
      }
    });
  }
}
import { startsWith, uniq } from "lodash";
import React from "react";
import { CollarMaterial, getLeather, getLeatherColorByName } from "../../options";
import { LeatherColors } from "../../settings";
import { AbstractCollar, checkNameTag } from "../AbstractCollar";
import { AbstractCollarPricing } from "../AbstractPricing";
import { AbstractCollarDefinition } from "../BaseCollarDefinition";
import { AdjustmentHoles, CollarWidth, Decorations, Locking, NamePlate, PowderCoated, RingAttachment, SizeSlider, Stiching } from "../../components";
import { MaterialSelection } from "../../components/MaterialSelection";
import { PermanentePricing } from "./Pricing";
import { PermanenteCollarDraw } from "./Draw";
import { ExtraBolts } from "../../components/ExtraBolts";


export interface PermanenteCollarDefinition extends AbstractCollarDefinition {
  type: 'PermanenteCollar';
  extraBolts: number;
}

export class PermanenteCollar extends AbstractCollar<PermanenteCollarDefinition> {
  name = "Permanente Collar";

  constructor(definition: PermanenteCollarDefinition) {
    super(definition);
    this.definition = this.cleanup(this.definition);
  }

  public cleanup(input: PermanenteCollarDefinition): PermanenteCollarDefinition {
    let definition = {
      ...this.baseCleanup(input),
    };

    return {
      ...definition,
      type: 'PermanenteCollar',
      lockingPost: null,
      DRingType: null,
      linerMaterial: null,
      neckSize: Math.max(input.neckSize || 16, this.getMinStrapSize()),
      holes: Math.min(input.holes || 5, this.getMaxNumberOfHoles()),
      studDistance: 0,
      powderCoated: null,
      primaryStiching: null,
      studCount: input.studCount || 0,
      rivetCount: input.rivetCount || 0,
      extraBolts: input.extraBolts || 0,
    };
  }

  public getPricing(): PermanentePricing {
    return new PermanentePricing();
  }

  public getPrimaryOptions(): LeatherColors[] | null {
    const gatorStrap = getLeather('GatorStrap');
    return [gatorStrap];
  }

  public getCollarWidthOptions(): number[] {
    const { definition } = this;
    const material = definition.primaryMaterial ?? 'GatorStrap';
    const options = this.getPrimaryOptions();
    const color = getLeatherColorByName(options, material) ?? options[0].colors[0];
    return uniq(color.sizes).filter(t => t < 1.5);
  }

  public getRingOptions() {
    return [];
  }

  public getDrawObject(): PermanenteCollarDraw {
    return new PermanenteCollarDraw(this.definition);
  }

  public materialSelection(onUpdate: (def: PermanenteCollarDefinition) => void): JSX.Element {
    const { definition } = this;
    const linerOptions = this.getLinerOptions();

    return <>
      <MaterialSelection
        title="Primary Strap"
        type={definition.primaryMaterial}
        onUpdate={(m) => onUpdate({ ...definition, primaryMaterial: m })}
        options={this.getPrimaryOptions()}
      />
      {linerOptions && (<MaterialSelection
        title="Inside Liner"
        type={definition.linerMaterial}
        onUpdate={(m) => onUpdate({ ...definition, linerMaterial: m })}
        options={linerOptions}
      />)}
    </>;
  }

  public optionsSelection(onUpdate: (def: PermanenteCollarDefinition) => void): JSX.Element {
    const { definition } = this;
    let pricing = this.getPricing();
    // TODO: Add option to add spare boats - $3 unit
    return <>
      <div className="col-sm-6 product-options form-horizontal" style={{ padding: '10px' }}>
        <h3>Sizing</h3>
        <SizeSlider
          minSize={this.getMinStrapSize()}
          onUpdate={size => onUpdate({ ...definition, neckSize: size })}
          size={definition.neckSize}
        />
        <CollarWidth
          options={this.getCollarWidthOptions()}
          onUpdate={size => onUpdate({ ...definition, primaryWidth: size })}
          size={definition.primaryWidth}
        />
      </div>
      <div className="col-sm-6" style={{ padding: '10px' }}>
        <h3>Options</h3>
        <table className="table">
          <tbody>
            <ExtraBolts
              onUpdate={v => onUpdate({ ...definition, extraBolts: v })}
              value={definition.extraBolts}
              price={pricing.extraBolts(definition.extraBolts || 2)}
            />
          </tbody>
        </table>
      </div>
    </>;
  }

  public prettyPrint() {
    const { definition } = this;
    let output = super.prettyPrint();

    if (definition.extraBolts > 0) {
      output += `Extra bolts: ${definition.extraBolts}\n`;
    }

    return output;
  }
}

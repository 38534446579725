import { BaseCollarStrapDraw, BuckleDraw, RivetDraw } from "../../drawing";
import { threadColors } from "../../settings";
import { AbstractCollarDraw } from "../AbstractDraw";
import { AccentStrapCollarDefinition } from "./Collar";

export class AccentStrapCollarDraw extends AbstractCollarDraw<AccentStrapCollarDefinition> {

    get rightRivetPosition(): number {
      return this.holesStartX - 1.0;
    }
  
    get leftRivetPosition(): number {
      let start = this.x + 2.25;
      if (this.hasPetRing) {
        start += 1;
      }
  
      if (this.addLockingPost) {
        if (["padlockplate", "padlockstaple"].includes(this.opts.lockingPost)) {
          start += 1.5;
        } else {
          start += 0.25;
        }
      }
      return start;
    }
  
    constructor(opts: AccentStrapCollarDefinition) {
      super(opts);
  
      if (this.hasPetRing) {
        this.addDRing(this.objs, -this.strapSize / 2 + 1.5, false, true);
      }
  
      this.addTopStrap(this.objs, true);

      if (opts.studDistance > 0) {
        let leftPad = this.leftRivetPosition + 0.75; 
        let rightPad = this.rightRivetPosition - 0.5;
        let centerPadding = 0;
  
        this.addStuds(this.objs, leftPad, rightPad, centerPadding);
      }
  
      this.objs.push(new BuckleDraw({
        x: -this.strapSize / 2,
        y: 0,
        h: this.primaryWidth,
        isTopStrap: false,
        color: this.metalColor,
      }));
  
      this.objs.push(new BaseCollarStrapDraw({
        color: this.getLeatherColorByName(opts.primaryMaterial),
        x: (this.topStrapSize - this.strapSize) / 2,
        y: 0,
        w: this.topStrapSize,
        h: this.primaryWidth,
        isTopStrap: false,
        addRightRounded: true,
        seamColor: this.getColorByName(threadColors, opts.primaryStiching)
      }));
  
      const start = this.leftRivetPosition - 0.25;
      const end = this.rightRivetPosition + 0.25;
  
      const accent = new BaseCollarStrapDraw({
        color: this.getLeatherColorByName(opts.accentMaterial),
        x: start + (end - start) / 2,
        y: 0,
        w: end - start,
        h: opts.accentWidth,
        edgeRadius: 0.25,
        isTopStrap: true,
        seamColor: this.getColorByName(threadColors, opts.accentStiching)
      });
  
      this.objs.push(accent);
  
      this.addRivet(this.objs, this.leftRivetPosition);
      this.addRivet(this.objs, this.rightRivetPosition);
    }
  
    public addRivet(objs, xPos) {
      // Inside of the accent strap
      if(this.leftRivetPosition <= xPos && xPos <= this.rightRivetPosition) {
        const { accentWidth } = this.opts;
        this.addSpreadedRivet(objs, xPos, accentWidth);
        return;
      }
  
      super.addRivet(objs, xPos);
    }
  }
  
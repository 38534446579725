import { some } from "lodash";
import { BaseCollarObject, DRingDraw, HolesDraw, ImageStud, LockingPostDraw, NameTagDraw, RivetDraw } from "../drawing";
import { getLeatherColorByName, studArray } from "../options";
import { leatherColors, powderCoatedList } from '../settings';
import { AbstractStrapDefinition } from "./AbstractStrapDefinition";

export abstract class AbstractStrapDraw<T extends AbstractStrapDefinition> {
  objs: BaseCollarObject[];
  opts: T;

  constructor(opts: T) {
    this.opts = opts;
    this.objs = [];
  }

  abstract get strapSize(): number;
  get primaryWidth(): number {
    return this.opts.primaryWidth;
  }

  get hasPetRing(): boolean {
    return this.opts.DRingType === "Front+Pet" || this.opts.DRingType === "Pet";
  }

  //The amount of space after the holes
  get extraSpacingOnEnd(): number {
    if (this.addLockingPost) {
      return 3.0 + Math.max(this.numberOfHoles - 5, 0) * 0.5;
    }

    return 2.0;
  }

  get numberOfHoles() { return this.opts.holes || 4; }

  get addLockingPost() { return !!this.opts.lockingPost; }

  get holeDistanceApart() { return 0.5; }

  get topStrapSize() { return this.w; }

  get topStrapHeight() { return this.primaryWidth; }

  get rightRivetPosition(): number {
    return this.strapSize / 2 - 3.0 - Math.max(0.0, this.numberOfHoles - 5) * 0.5;
  }

  get metalColor(): string | undefined {
    const color = this.opts.powderCoated;
    return color && powderCoatedList.find(t => t.name === color)?.color;
  }

  get leftRivetPosition(): number {
    let start = this.x + 3.5;
    if (this.hasPetRing) {
      start += 0.5;
    }

    return start;
  }

  get collarCenter(): number {
    if (this.hasPetRing) {
      return 1.0;
    }
    return 0.0;
  }

  get holesStartX(): number {
    const holesX = this.strapSize / 2 + this.extraSpacingOnEnd - this.holeDistanceApart * this.numberOfHoles;

    if (this.addLockingPost) {
      return holesX - 3.25;
    } else {
      return holesX - 1.75;
    }
  }

  get x() { return -(this.strapSize + 1) / 2; }
  get y() { return -this.primaryWidth; }
  get w() { return this.strapSize + this.extraSpacingOnEnd; }
  get h() { return this.primaryWidth * 2; }

  draw(ctx: CanvasRenderingContext2D) {
    this.objs.forEach((x) => this.drawObject(ctx, x, 'drawBack'));
    this.objs.forEach((x) => this.drawObject(ctx, x, 'drawBaseStrap'));
    this.objs.forEach((x) => this.drawObject(ctx, x, 'drawMiddleLayer'));
    this.objs.forEach((x) => this.drawObject(ctx, x, 'drawTopStrap'));
    this.objs.forEach((x) => this.drawObject(ctx, x, 'drawOverlay'));
    this.objs.forEach((x) => this.drawObject(ctx, x, 'drawTextOverlay'));
  }

  drawObject(ctx: CanvasRenderingContext2D, obj: BaseCollarObject, funcName: string) {
    ctx.save();
    ctx.translate(obj.x, obj.y);

    obj.children.forEach((child) => {
      this.drawObject(ctx, child, funcName);
    });

    obj[funcName](ctx);
    ctx.restore();
  }

  getColorByName(list, name) {
    if (name === null)
      return null;

    var items = list.filter(t => t.name === name);

    if (items.length === 0)
      return "#FFF";

    return items[0].color;
  }

  getLeatherColorByName(name) {
    const color = getLeatherColorByName(leatherColors, name);

    if (color)
      return color.color;

    return "#FFF";
  }

  getAllByType(filter: (obj: BaseCollarObject) => boolean) {
    const result: BaseCollarObject[] = [];

    function iterate(items: BaseCollarObject[]) {
      for (const item of items) {
        iterate(item.children);

        if (filter(item)) {
          result.push(item);
        }
      }
    }

    iterate(this.objs);
    return result;
  }

  addTopStrap(objs: BaseCollarObject[], DRingBottomLayer = false) {
    const { DRingType } = this.opts;
    if (!objs) {
      objs = this.objs;
    }

    if (DRingType === "Front+Pet") {
      this.addDRing(objs, this.collarCenter, undefined, DRingBottomLayer);
    }

    if (["Front", "3 Ring", "5 Ring"].includes(DRingType)) {
      this.addDRing(objs, 0, undefined, DRingBottomLayer);
    }

    if (DRingType === "3 Ring") {
      let left = Math.max(-this.strapSize / 4, this.leftRivetPosition + 1.5);
      let right = Math.min(this.strapSize / 4, this.rightRivetPosition - 1.5);

      this.addDRing(objs, left, undefined, DRingBottomLayer);
      this.addDRing(objs, right, undefined, DRingBottomLayer);
    }

    if (DRingType === "5 Ring") {
      this.addDRing(objs, this.leftRivetPosition + 0.5, undefined, DRingBottomLayer);
      this.addDRing(objs, this.rightRivetPosition - 0.5, undefined, DRingBottomLayer);

      this.addDRing(objs, (this.leftRivetPosition + 0.5) / 2, undefined, DRingBottomLayer);
      this.addDRing(objs, (this.rightRivetPosition - 0.5) / 2, undefined, DRingBottomLayer);
    }

    //Rivet for the buckle
    this.addSpreadedRivet(this.objs, this.x + 1.5, this.topStrapHeight);

    this.addHoles(objs);
  }

  public addRivet(objs: BaseCollarObject[], xPos: number) {
    this.addSpreadedRivet(objs, xPos, this.topStrapHeight);
  }

  public addDRing(objs: BaseCollarObject[], xPos: number, single?: boolean, bottomLayer = false) {
    objs.push(new DRingDraw({
      x: xPos,
      y: 0,
      h: this.topStrapHeight,
      bottomLayer,
      color: this.metalColor,
    }));

    this.addRivet(objs, xPos + 0.5);
    this.addRivet(objs, xPos - 0.5);
  }

  public addHoles(objs: BaseCollarObject[]) {
    const { lockingPost } = this.opts;
    const holesX = this.holesStartX;

    if (this.addLockingPost) {
      objs.push(new HolesDraw({
        count: this.numberOfHoles,
        x: holesX,
        distance: this.holeDistanceApart
      }));

      objs.push(new HolesDraw({
        count: this.numberOfHoles,
        x: holesX + 3.0,
        distance: this.holeDistanceApart,
        holeSize: 1.0 / 8.0,
        drawSlots: lockingPost === "padlockstaple"
      }));

      var lockingPostPosition = 2.5;

      if (this.hasPetRing) {
        lockingPostPosition += 1;
      }

      objs.push(new LockingPostDraw({
        x: this.x + lockingPostPosition,
        y: 0,
        type: lockingPost,
        color: this.metalColor,
      }));
    } else {
      objs.push(new HolesDraw({
        count: this.numberOfHoles,
        x: holesX,
        distance: this.holeDistanceApart
      }));
    }
  }

  public addSpreadedRivet(objs: BaseCollarObject[], xPos: number, width: number, count: number | undefined = undefined) {

    if (!count) {
      count = 1;
      if (width >= 2.5) {
        count = 5;
      } else if (width >= 2) {
        count = 4;
      } else if (width >= 1.5) {
        count = 3;
      } else if (width >= 1) {
        count = 2;
      }
    }

    let step = width / count;
    for (let y = -width / 2 + step / 2; y < width / 2; y += step) {
      objs.push(new RivetDraw({
        x: xPos,
        y: y,
        color: this.metalColor,
      }));
    }
  }
}

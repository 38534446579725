import { map } from "lodash";
import React from "react";
import { Component } from "react";
import { CollarCanvasBase } from "./Canvas";
import numeral from 'numeral';
interface CollarPickerProps {
  clickTab: (tab: string) => void;
  updateCollar: (name: string) => void;
  collarDetails: any;
  options: any;
}

export class StrapPicker extends Component<CollarPickerProps> {
  constructor(props: CollarPickerProps) {
    super(props);

    this.setCollar = this.setCollar.bind(this);
  }

  setCollar(name: string) {
    this.props.updateCollar(name);
    this.props.clickTab("materials");
  }

  render() {
    var renders = map(this.props.options, (item, id) => {
      var clone = { ...this.props.collarDetails, collar: id };
      var instance = new item(clone as any);
      var className = "CollarOption";

      if (this.props.collarDetails.collar === id) {
        className += " CollarOption-active";
      }

      let pricing = instance.getPricing();
      let price = numeral(pricing.getTotal(instance.definition)).format('$0,0.00');

      return (<div key={"strap-" + id} className={className} onClick={() => this.setCollar(instance.definition.type)}>
        <h3 style={{ color: '#338a3e', fontWeight: 'bold', margin: '10px', float: 'right' }}>{price}</h3>
        <h3 style={{ color: '#338a3e', fontWeight: 'bold', margin: '10px' }}>{instance.name}</h3>
        <CollarCanvasBase width={800} height={125} draw={instance.getDrawObject()} disableBackground={true} />
      </div>);
    });

    return (<div>{renders}</div>);
  }
}
import { some } from "lodash";
import { BaseCollarObject, DRingDraw, ImageStud, NameTagDraw } from "../drawing";
import { studArray } from "../options";
import { AbstractStrapDraw } from "../strap/AbstractDraw";
import { AbstractCollarDefinition } from "./BaseCollarDefinition";

export class AbstractCollarDraw<T extends AbstractCollarDefinition> extends AbstractStrapDraw<T> {
  
  get strapSize() {
    if (this.opts.neckSize) {
      return this.opts.neckSize + 1.5;
    }

    return 16;
  }

  public addTopStrap(objs: BaseCollarObject[], DRingBottomLayer = false) {
    super.addTopStrap(objs, DRingBottomLayer);
    this.addNameTag(objs);
  }


  public addNameTag(objs: BaseCollarObject[]) {
    const { nameTag, DRingType } = this.opts;
    if (!nameTag || this.primaryWidth < 0.75) {
      return;
    }

    let collarCenter = 0.0;
    if (DRingType === "Front+Pet" || DRingType === "Pet") {
      collarCenter = 1.0;
    }

    let offset = 1.5;

    if (DRingType === "Pet")
      offset = 1.0;

    let nameTagObj = new NameTagDraw({
      type: nameTag,
      x: collarCenter + offset,
      y: 0
    });

    if (DRingType === "Front+Pet" || DRingType === "Pet") {
      if (this.strapSize <= 17) {
        nameTagObj.opts.x = collarCenter - nameTagObj.size - offset;
      }
    }

    if (nameTagObj.opts.x + nameTagObj.size >= (this.rightRivetPosition - 1.0)) {
      nameTagObj.opts.x = collarCenter - nameTagObj.size - offset;
    }

    objs.push(nameTagObj);
  }

  addStuds(objs, leftRivet, rightRivet, centerPadding) {
    const type = studArray[0].group + " " + studArray[0].name;
    var dRingPositions = objs.filter(t => t instanceof DRingDraw).map(t => t.x);
    var nameTag = objs.filter(t => t instanceof NameTagDraw);
    var step = this.opts.studDistance;

    let inRange = (x) => {
      if (some(dRingPositions, (x2) => (Math.abs(x2 - x) <= 1.0)))
        return true;

      if (some(nameTag, (tag) => (x > (tag.x - 0.5)) && (x < (tag.x + tag.size + 0.5))))
        return true;

      return false;
    }

    for (let i = this.collarCenter + -centerPadding; i >= leftRivet; i -= step) {
      if (inRange(i))
        continue;

      objs.push(new ImageStud({
        type: type,
        x: i,
        y: 0
      }));
    }

    for (let i = this.collarCenter + centerPadding; i <= rightRivet; i += step) {
      if (inRange(i))
        continue;

      objs.push(new ImageStud({
        type: type,
        x: i,
        y: 0
      }));
    }
  }
}

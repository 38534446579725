import React from "react";
import { BootstrapSlider } from "./BootstrapSlider";

interface SizeSliderProps {
  minSize: number;
  size: number;
  onUpdate(value: number): void;
}

export const SizeSlider: React.FC<SizeSliderProps> = props => {
  const { minSize, size, onUpdate } = props;

  return <div className="form-group">
    <label className="control-label col-sm-4">
      Actual Neck Size ({size}")
      <a href="https://lagartogear.com/index.php?route=information/information&information_id=12">
        <i className="fa fa-question-circle" aria-hidden="true" title="Click for help page" id="neckSizeHelp"></i>
      </a>
    </label>
    <div className="col-sm-8">
      <div className="checkbox">
        <BootstrapSlider
          minSize={minSize}
          maxSize={30}
          size={size}
          onUpdate={onUpdate}
          formatter={val => `${val}"`}
        />
      </div>
    </div>
  </div>;
}

interface CollarWidthProps {
  options: number[];
  size: number;
  title?: string;
  onUpdate(value: number): void;
}

export const CollarWidth: React.FC<CollarWidthProps> = props => {
  const { options, size, onUpdate, title } = props;

  return <div className="form-group">
    <label className="control-label col-sm-4">{title ?? 'Collar Width'}</label>
    <div className="col-sm-8">
      <div className="checkbox">
        <BootstrapSlider
          key={options.join(',')}
          minSize={0}
          maxSize={options.length - 1}
          size={size}
          onUpdate={onUpdate}
          ticks={options}
          ticksLabels={options.map((t) => t + '"')}
          formatter={val => `${val}"`}
          tooltip='hide'
        />
      </div>
    </div>
  </div>;
}

interface AdjustmentHolesProps {
  maxHoles: number;
  holes: number;
  onUpdate(value: number): void;
}

export const AdjustmentHoles: React.FC<AdjustmentHolesProps> = props => {
  const { maxHoles, holes, onUpdate } = props;

  return <div className="form-group">
    <label className="control-label col-sm-4">Adjustment Holes ({holes})</label>
    <div className="col-sm-8">
      <div className="checkbox">
        <BootstrapSlider
          minSize={1}
          maxSize={maxHoles}
          size={holes}
          onUpdate={onUpdate}
        />
      </div>
    </div>
  </div>;
}

import React from 'react';
import Toggle from 'react-bootstrap-toggle';

interface DecorationsProps {
  onUpdate(studCount: number, studDistance: number): void;
  studCount: number;
  studDistance: number;
}

export const Decorations: React.FC<DecorationsProps> = props => {
  const { studCount, studDistance, onUpdate } = props;

  return <tr style={{ verticalAlign: 'middle' }}>
    <td style={{ verticalAlign: 'middle' }}>
      <Toggle onClick={v => onUpdate(0, v ? 1 : 0)} active={studDistance > 0} />
    </td>
    <td>
      <div style={{ fontSize: "21px" }}>Add Decorations</div>
      <small>
        {studDistance > 0 && <>Decorations counter: {studCount}<br /></>}
        Use this tool to count how many spikes or studs will fit, then
        <a href='https://lagartogear.com/index.php?route=product/category&path=74' target='_blank'>
          {' '} order them here
        </a>.
      </small>
      {studDistance > 0 && <DecorationsOptions {...props} />}
    </td>
    <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
      <h3 style={{ margin: '0px' }}></h3>
    </td> 
  </tr>;
};

const DecorationsOptions: React.FC<DecorationsProps> = props => {
  const { studCount, studDistance, onUpdate } = props;
  const options = [0.5, 0.625, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0];

  return <div>
    Select distance between studs:
    <select
      name="studdistance"
      value={studDistance.toString()}
      onChange={e => onUpdate(studCount, parseFloat(e.target.value))}
    >
      {options.map(o => <option key={o} value={o}>{o}"</option>)}
    </select>
  </div>;
};

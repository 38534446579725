import { splitColor } from "../../strap/AbstractPricing";
import { AbstractCollarPricing, PowderCoatingPrice } from "../AbstractPricing";
import { PrimaryStrapCollarDefinition } from "./Collar";



export class PrimaryStrapPricing extends AbstractCollarPricing<PrimaryStrapCollarDefinition> {

  public getBasePrice() {
    return 25;
  }

  public getTotal(def: PrimaryStrapCollarDefinition): number {
    let total = 0;
    total += this.getDRing(def.primaryWidth, def.DRingType);
    total += this.getLinerPricing(def);
    return super.getTotal(def) + total;
  }

  public getLinerPricing(def: PrimaryStrapCollarDefinition) {
    const primary = splitColor(def.primaryMaterial);

    // Charge for every additional inch with neck sizes bigger than 17" 
    let extraNeckSize = (def.neckSize - 17);
    let additional = 0;

    if (extraNeckSize <= 0) {
      extraNeckSize = 0;
    }

    if (primary.fabric != "GatorStrap") {
      if (def.primaryWidth == 0.5) { additional += extraNeckSize * 3; }
      if (def.primaryWidth == 0.625) { additional += extraNeckSize * 3; }
      if (def.primaryWidth == 0.75) { additional += extraNeckSize * 3; }
      if (def.primaryWidth == 1) { additional += extraNeckSize * 3; }
      if (def.primaryWidth == 1.25) { additional += extraNeckSize * 5; }
      if (def.primaryWidth == 1.5) { additional += extraNeckSize * 7; }
      if (def.primaryWidth == 2) { additional += extraNeckSize * 10; }
    } else {
      if (def.primaryWidth == 0.625) { additional += extraNeckSize * 1; }
      if (def.primaryWidth == 1.0) { additional += extraNeckSize * 1; }
      if (def.primaryWidth == 1.5) { additional += extraNeckSize * 1; }
      if (def.primaryWidth == 2.0) { additional += extraNeckSize * 1; }
    }

    return additional;
  }

  public getPowderedCoating(input: PowderCoatingPrice) {
    let extra = input.collarWidth < 1 ? 4 : 6;

    return extra + super.getPowderedCoating(input);
  }

  public materialCost(def: PrimaryStrapCollarDefinition) {
    const primary = splitColor(def.primaryMaterial);
    let additional = 0.0;
    
    if (primary.fabric == "Latigo") {
      const liner = splitColor(def.linerMaterial);
      if (def.primaryWidth == 0.5) {
        additional += 17.0;
      } else if (def.primaryWidth == 0.625) {
        additional += 20.0;
      } else if (def.primaryWidth == 0.75) {
        additional += 25.0;
      } else if (def.primaryWidth == 1.0) {
        additional += 29.0;

        if (liner.fabric == "Rabbit Fur") { additional += 35.0; }
        if (liner.fabric == "Wool") { additional += 45.0; }
      } else if (def.primaryWidth == 1.25) {
        additional += 59.0;

        if (liner.fabric == "Rabbit Fur") { additional += 45.0; }
        if (liner.fabric == "Wool") { additional += 55.0; }
      } else if (def.primaryWidth == 1.5) {
        additional += 85.0;

        if (liner.fabric == "Rabbit Fur") { additional += 55.0; }
        if (liner.fabric == "Wool") { additional += 65.0; }
      } else {
        additional += 120.0;

        if (liner.fabric == "Rabbit Fur") { additional += 75.0; }
        if (liner.fabric == "Wool") { additional += 85.0; }
      }
    }

    if (primary.fabric == "GatorStrap") {
      if (def.primaryWidth == 0.625) {
        additional += 0.0;
      } else if (def.primaryWidth == 1.0) {
        additional += 11.0;
      } else if (def.primaryWidth == 1.5) {
        additional += 30.0;
      } else {
        additional += 45.0;
      }
    }

    return additional;
  }
}

import React from 'react';
import Toggle from 'react-bootstrap-toggle';
import numeral from 'numeral';
import * as options from '../options';


interface StichingProps {
  onUpdate(value: string | null): void;
  value: string | null;
  disabled: string | null;
  title: string;
  price: number;
}

export const Stiching: React.FC<StichingProps> = props => {
  const { value, onUpdate, disabled, title, price } = props;
  let style: React.CSSProperties = {
    verticalAlign: 'middle'
  }

  let message = "Decorative purposes only.";

  if (disabled !== null) {
    style.color = '#999';
    message = disabled;
  }

  return <tr style={style}>
    <td style={{ verticalAlign: 'middle' }}>
      <Toggle
        onClick={v => onUpdate(v ? "red" : null)}
        active={value !== null}
        disabled={!!disabled}
      />
    </td>
    <td>
      <div style={{ fontSize: "21px" }}>{title}</div>
      <small>{message}</small>
      {value !== null && <StichingOptions {...props} />}
    </td>
    <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
      <h3 style={{ margin: '0px' }}>{numeral(price).format('$0,0.00')}</h3>
    </td>
  </tr>;
}


const StichingOptions: React.FC<StichingProps> = props => {
  const { value, onUpdate } = props;

  return <div>
    Choose thread color:
    <select name="threadColor" value={value} onChange={e => onUpdate(e.target.value)}>
      {options.threadColors.map(entry => {
        return <option key={entry.name} value={entry.name}>{entry.name}</option>;
      })}
    </select>
  </div>;
}
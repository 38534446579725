import React from 'react';
import './App.css';

import numeral from 'numeral';
import * as BaseApp from './BaseApp';
import { CollarCanvasBase } from './Canvas';
import { StrapPicker } from './StrapPicker';
import { ImageStud, RivetDraw } from './drawing';
import { getCollarFromJson, collarList } from './collars';

class CollarApp extends BaseApp.BaseApp<any> {
  constructor(props) {
    super(props);

    this.updateCollarDetails = this.updateCollarDetails.bind(this);

    if (this.state.definition.type) {
      this.state = {
        ...this.state,
        selectedTab: 'sizing'
      }
    }
  }

  getTabs() {
    const tabs = [];
    const { definition } = this.state;
    const a = getCollarFromJson(definition);

    tabs.push({
      id: "style",
      name: "Style",
      render: () => <StrapPicker
        collarDetails={definition}
        updateCollar={n => this.updateCollarDetails({ ...definition, type: n })}
        clickTab={this.clickTab}
        options={collarList}
      />
    });

    tabs.push({
      id: "materials",
      name: "Materials",
      render: () => a.materialSelection(this.updateCollarDetails),
    });

    tabs.push({
      id: "sizing",
      name: "Sizing & Options",
      render: () => a.optionsSelection(this.updateCollarDetails),
    });

    return tabs;
  }

  totalPrice(details: any) {
    const collar = getCollarFromJson(details);
    const pricing = collar.getPricing();
    return pricing.getTotal(collar.definition);
  }

  updateCollarDetails(input: any) {
    const collar = getCollarFromJson(input);
    const draw = collar.getDrawObject();
    const details = {
      ...collar.definition,
      studCount: draw.objs.filter(t => t instanceof ImageStud).length,
      rivetCount: draw.getAllByType(t => t instanceof RivetDraw).length,
    };

    this.setState({
      definition: details,
      price: this.totalPrice(details),
    });
  }

  render() {
    window.$('#priceTag').text(numeral(this.state.price).format('$0,0.00'))

    var progressList = this.getProgressElement();
    let body = this.getBody();
    const a = getCollarFromJson(this.state.definition);

    return <div>
      <ol className="progress-track">
        {progressList}
      </ol>
      {
        this.state.selectedTab !== "style" && (<div className="polaroid" style={{ position: "relative" }}>
          <CollarCanvasBase
            width={1200}
            height={200}
            draw={a.getDrawObject()}
            disableBackground={false}
          />
        </div>)
      }
      <div><small>Adjusting size, width, and materials will affect the price shown. The image above is a digital preview, and does not perfectly reflect the final product. If you have any concerns, please ask Lagarto before ordering.</small></div>
      <div className='row'>
        {body}
      </div>
      {this.getMoveButtons()}
    </div>
  }
}

export { CollarApp };

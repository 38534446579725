import { BaseCollarStrapDraw, BuckleDraw, RivetDraw } from "../../drawing";
import { threadColors } from "../../settings";
import { AbstractCollarDraw } from "../AbstractDraw";
import { PrimaryPlusPadCollar, PrimaryPlusPadCollarDefinition } from "./Collar";

export class PrimaryPlusPadCollarDraw extends AbstractCollarDraw<PrimaryPlusPadCollarDefinition> {

    get topStrapHeight() { 
      return this.opts.primaryWidth; 
    }
  
    get rightRivetPosition(): number {
      return this.holesStartX - 0.75;
    }
  
    get leftRivetPosition(): number {
      let start = this.x + 3.5;
      if (this.hasPetRing) {
        start += 1;
      }
  
      if (this.addLockingPost) {
        if (["padlockplate", "padlockstaple"].includes(this.opts.lockingPost)) {
          start += 1.5;
        } else {
          start += 0.25;
        }
      }
      return start;
    }
  
    constructor(opts: PrimaryPlusPadCollarDefinition) {
      super(opts);
  
      this.addTopStrap(this.objs);
  
      if (this.hasPetRing) {
        this.addDRing(this.objs, -this.strapSize / 2 + 1.5);
      }

      if (opts.studDistance > 0) {
        let leftPad = this.leftRivetPosition + 0.5;
        let rightPad = this.rightRivetPosition - 0.5;
        let centerPadding = opts.DRingType === "Pet" ? 0 : 1.5;
        this.addStuds(this.objs, leftPad, rightPad, centerPadding);

        if (!opts.DRingType || opts.DRingType === "Pet") {
          this.objs.push(new RivetDraw({
            x: this.collarCenter,
            y: 0.0,
            color: this.metalColor,
          }));
        }
      }
  
      this.addSpreadedRivet(this.objs, this.rightRivetPosition, this.topStrapHeight);
      this.addSpreadedRivet(this.objs, this.leftRivetPosition, this.topStrapHeight);
  
      this.objs.push(new BaseCollarStrapDraw({
        color: this.getLeatherColorByName(opts.primaryMaterial),
        x: (this.topStrapSize - this.strapSize) / 2,
        y: 0,
        w: this.topStrapSize,
        h: this.primaryWidth,
        isTopStrap: true,
        seamColor: this.getColorByName(threadColors, opts.primaryStiching),
        addRightRounded: true
      }));
  
      this.objs.push(new BaseCollarStrapDraw({
        color: this.getLeatherColorByName(opts.padMaterial),
        x: 0,
        w: this.strapSize,
        h: opts.padWidth,
        seamColor: this.getColorByName(threadColors, opts.padStiching),
        isTopStrap: false,
      }));
  
      this.objs.push(new BuckleDraw({
        x: -this.strapSize / 2,
        y: 0,
        h: this.primaryWidth,
        isTopStrap: true,
        color: this.metalColor,
      }));
    }
  }


interface BaseCollarObjectOpts {
  x?: number;
  y?: number;
}

export class BaseCollarObject<T = {}> {
  public children: BaseCollarObject<any>[];
  public opts: T & BaseCollarObjectOpts;

  constructor(opts?: T & BaseCollarObjectOpts) {
    this.opts = opts;
    this.children = [];
  }

  get x() { return this.opts.x || 0; }
  get y() { return this.opts.y || 0; }

  drawBack(ctx) { }

  drawBaseStrap(ctx) { }

  drawMiddleLayer(ctx) { }

  drawTopStrap(ctx) { }

  drawOverlay(ctx) { }

  drawTextOverlay(ctx) { }

  drawLine(ctx, x1, y1, x2, y2) {
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
  }

  pathRoundedRect(ctx, x, y, width, height, radius) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
  }
}
import { splitColor } from "../../strap/AbstractPricing";
import { isGatorStrap } from "../../strap/AbstractStrap";
import { AbstractCollarPricing, PowderCoatingPrice } from "../AbstractPricing";
import { MartinGaleCollarDefinition } from "./Collar";



export class MartinGaleCollarPricing extends AbstractCollarPricing<MartinGaleCollarDefinition> {

  public getBasePrice() {
    return 60;
  }

  public getTotal(def: MartinGaleCollarDefinition): number {
    let total = 0;
    total += this.getLinerPricing(def);
    return super.getTotal(def) + total;
  }

  public getPowderedCoating(input: PowderCoatingPrice) {
    let extra = input.collarWidth < 1 ? 4 : 6;

    return extra + super.getPowderedCoating(input);
  }

  public materialCost(def: MartinGaleCollarDefinition) {
    let additional = 0.0;

    if(!isGatorStrap(def.primaryMaterial)) {
      additional += 15.0;
    }

    // older code, deleted function - Charge for every additional 1" with neck sizes bigger than 18" 
    // var additionalNeckSize = (def.neckSize - 18);

    // if (additionalNeckSize <= 0) {
    //   additionalNeckSize = 0;
    // }
    //
    // additional += additionalNeckSize * 3.0;
    //
    return additional;
  }

  public getLinerPricing(def: MartinGaleCollarDefinition) {
    const primary = splitColor(def.primaryMaterial);

    // Charge for every additional inch with neck sizes bigger than 17" 
    let extraNeckSize = (def.neckSize - 17);
    let additional = 0;

    if (extraNeckSize <= 0) {
      extraNeckSize = 0;
    }

    if (primary.fabric != "GatorStrap") {
      additional += extraNeckSize * 3;
    } else {
      additional += extraNeckSize * 1;
    }

    return additional;
  }
}

import { some } from "lodash";
import { BaseCollarObject, DRingDraw, HolesDraw, ImageStud, LockingPostDraw, NameTagDraw, RivetDraw } from "../drawing";
import { studArray } from "../options";
import { powderCoatedList } from '../settings';
import { AbstractStrapDraw } from "../strap/AbstractDraw";
import { AbstractCuffDefinition } from "./BaseCuffDefinition";

export class AbstractCuffDraw<T extends AbstractCuffDefinition> extends AbstractStrapDraw<T> {
  
  get strapSize() {
    if (this.opts.sizes[0]) {
      return this.opts.sizes[0] + 1.5;
    }

    return 16;
  }


}

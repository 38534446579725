import { AbstractCollar } from './AbstractCollar';
import { AccentStrapCollar } from './AccentStrapCollar';
import { PrimaryPlusPadCollar } from './PrimaryPlusPadStrapCollar';
import { PrimaryStrapCollar } from './PrimaryStrap';
import { PermanenteCollar } from './Permanente';
import { MartinGaleCollar } from './MartinGale';

export * from './AbstractCollar';
export * from './PrimaryStrap';
export * from './AccentStrapCollar';
export * from './PrimaryPlusPadStrapCollar';
export * from './AbstractDraw';
export * from './MartinGale';
export * from './Permanente';

export const collarList = {
  "PrimaryStrap": PrimaryStrapCollar,
  "AccentStrapCollar": AccentStrapCollar,
  "PrimaryPlusPadCollar": PrimaryPlusPadCollar,
  "PermanenteCollar": PermanenteCollar,
  "MartinGaleCollar": MartinGaleCollar,
};

export function getCollarFromJson(a: any): AbstractCollar<any> {
  if (a.type === 'PrimaryStrap') {
    return new PrimaryStrapCollar(a);
  }

  if (a.type === 'PrimaryPlusPadStrap') {
    return new PrimaryPlusPadCollar(a);
  }

  if (a.type === 'PermanenteCollar') {
    return new PermanenteCollar(a);
  }

  if (a.type === 'MartinGaleCollar') {
    return new MartinGaleCollar(a);
  }

  return new AccentStrapCollar(a);
}
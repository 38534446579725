import { capitalize, startsWith } from "lodash";
import React from "react";
import { CollarMaterial, getLeatherByName, getLeatherColorByName } from "../options";
import { LeatherColor, LeatherColors } from '../settings';

interface MaterialSelectionProps {
  title: string;
  type: CollarMaterial;
  options: LeatherColors[];
  onUpdate(name: CollarMaterial): void;
}

export const MaterialSelection: React.FC<MaterialSelectionProps> = props => {
  const { type, options, onUpdate, title } = props;
  return <>
    <h3>{title}</h3>
    <div className="col-xs-12" style={{ paddingBottom: '20px' }}>
      <div className="col-xs-3">
        <div className="btn-group-vertical" role="group" aria-label="...">
          {options.map((opt) => {
            return <MaterialOptionButton key={opt.name} type={type} option={opt} onUpdate={onUpdate} />
          })}
        </div>
      </div>
      <div className="col-xs-9">
        <MaterialDetails options={options} type={type} onUpdate={onUpdate} />
      </div>
    </div>
  </>
}

interface MaterialOptionButtonProps {
  type: CollarMaterial;
  option: LeatherColors;
  onUpdate(name: CollarMaterial): void;
}

const MaterialOptionButton: React.FC<MaterialOptionButtonProps> = props => {
  const { type, option, onUpdate } = props;

  let className = "btn btn-default";
  if (type !== null && startsWith(type, option.name)) {
    className += " active";
  }

  return (<button
    key={option.name}
    type="button"
    className={className}
    onClick={() => onUpdate(option.name)}
  >
    {option.name}
  </button>);
}

interface MaterialDetailsProps {
  type: CollarMaterial;
  options: LeatherColors[];
  onUpdate(name: CollarMaterial): void;
}

const MaterialDetails: React.FC<MaterialDetailsProps> = props => {
  const { type, options, onUpdate } = props;
  const leather = getLeatherByName(options, type);
  const color = getLeatherColorByName(options, type);

  if (!leather || !color) {
    return (<div />);
  }

  let description = <>{leather.description || ""}</>;
  let colors = leather.colors;

  /*
  if (variable === "topStrapColor" && leather.name === "GatorStrap") {
    description = (<div>
      <div>{description}</div>
      <div>GatorStrap is available in 23 colors of 0.625 (5/8)" & 1" wide straps, 11 colors of 1.5", and 5 colors of 2".</div>
    </div>);
  }
  */

  const colorElems = colors.map((option, idx) => {
    let className = option.name === color.name ? 'selected' : '';
    return <span key={idx} className={className} onClick={() => onUpdate(leather.name + "/" + option.name)}>
      <MaterialIcon leather={leather} option={option} />
      {capitalize(option.name)}
    </span>
  });

  return <div>
    <div>
      {description}
    </div>
    <div>
      <div className="dropdown">
        <button className="dropbtn">
          <MaterialIcon leather={leather} option={color} />
          {capitalize(color.name)}
        </button>
        <div className="dropdown-content">
          {colorElems}
        </div>
      </div>
    </div>
  </div>;
}

const MaterialIcon: React.FC<{ leather: LeatherColors; option: LeatherColor; }> = props => {
  const { leather, option } = props;
  if (option?.color) {
    return (<span style={{ width: '20px', height: '20px', backgroundColor: option.color, float: 'left', marginRight: '5px' }} />);
  }

  if (leather && option?.image) {
    return (<img src={`image/${leather.folder}/${option.image}`} />);
  }

  return <></>;
}

import { AbstractStripePricing, PowderCoatingPrice, splitColor } from "../strap/AbstractPricing";
import { AbstractCollarDefinition } from "./BaseCollarDefinition";

export type { PowderCoatingPrice } from '../strap/AbstractPricing'

export abstract class AbstractCollarPricing<T extends AbstractCollarDefinition> extends AbstractStripePricing<T>{

  public abstract materialCost(def: T);
  public abstract getBasePrice();

  public getTotal(def: T): number {
    let total = this.getBasePrice();

    total += this.materialCost(def);
    total += this.getLockingPost(def.lockingPost);

    if (def.nameTag) {
      total += this.getNameTag();
    }

    if (def.primaryStiching) {
      total += this.getStiching();
    }

    if (def.powderCoated) {
      total += this.getPowderedCoating({
        DRingType: def.DRingType,
        collarWidth: def.primaryWidth,
        lockingPost: def.lockingPost,
        powderCoated: def.powderCoated,
        rivetCount: def.rivetCount,
      });
    }

    return total;
  }

  public getNameTag() {
    return 30;
  }

  public getLockingPost(lockingPost: string) {
    if (lockingPost === 'padlockpost') {
      return 26;
    }

    if (lockingPost === 'padlockplate') {
      return 32;
    }

    if (lockingPost === 'padlockstaple') {
      return 40;
    }

    return 0;
  }
}

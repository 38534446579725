import { CollarMaterial, getLeather, getLeatherByName, getLeatherColorByName } from "../options";
import { AbstractStrapDefinition } from "./AbstractStrapDefinition";
import { startsWith } from "lodash";
import { LockingPost, lockingPosts } from "../components";
import { DRingTypes, LeatherColors } from "../settings";
import { AbstractStripePricing, PowderCoatingPrice } from "./AbstractPricing";
import { AbstractStrapDraw } from "./AbstractDraw";

export function fromList(list: any[], value: any) {
  if (list.includes(value)) {
    return value;
  }
  return list[0];
}

export function checkLockingPost(definition: AbstractStrapDefinition) {
  if (!definition.lockingPost) {
    return;
  }

  if (definition.primaryWidth < 1) {
    definition.lockingPost = 'padlockpost';
  }

  if (definition.primaryMaterial.startsWith("GatorStrap")) {
    definition.lockingPost = 'padlockpost';
  }
}

export function isGatorStrap(name: CollarMaterial) {
  return startsWith(name, "GatorStrap");
}

export abstract class AbstractStrap<T extends AbstractStrapDefinition>  {
  public abstract name: string;

  constructor(public definition: Readonly<T>) {
  }

  public baseCleanup(input: Readonly<T>) {
    const linerOptions = this.getLinerOptions();
    const widthOptions = this.getCollarWidthOptions();

    let primaryMaterial = this.cleanupColorOption(input.primaryMaterial, this.getPrimaryOptions());
    let primaryWidth = widthOptions.includes(input.primaryWidth || 1) ? (input.primaryWidth || 1) : widthOptions[0];
    const DRingType = input.DRingType === undefined ? null : input.DRingType;
    let lockingPost = input.lockingPost || null;
    let primaryStiching = input.primaryStiching || null;

    if (lockingPost !== null) {
      lockingPost = fromList(this.getLockingOptions(primaryMaterial, primaryWidth).map(t => t.id), input.lockingPost);
    }

    if (primaryWidth <= 1.0 && lockingPost) {
      primaryStiching = null;
    }

    return {
      primaryStiching,
      primaryWidth,
      lockingPost,
      linerMaterial: linerOptions ? this.cleanupColorOption(input.linerMaterial, linerOptions) : null,
      primaryMaterial,
      DRingType,
    };
  }

  public cleanupColorOption(value: string | null, choices: LeatherColors[] | null, normal: string | null = null): string {
    let leather = getLeatherByName(choices, value);
    const color = getLeatherColorByName(choices, value);

    if (!leather) {
      leather = choices[0];
    }

    if (!color) {
      if (normal && leather.colors.some(t => t.name === normal)) {
        return `${leather.name}/${normal}`;
      }

      return `${leather.name}/${leather.colors[0].name}`;
    }

    return `${leather.name}/${color.name}`;
  }

  public getMaxNumberOfHoles() {
    const { definition } = this;
    if (definition.DRingType == "3 Ring" || definition.DRingType == "5 Ring") {
      return 5;
    }

    if (definition.lockingPost != null) {
      return 6;
    }

    return 9;
  }

  public getRingOptions(): string[] {
    return DRingTypes;
  }

  public getPowderPricing(): PowderCoatingPrice {
    const { DRingType, primaryWidth, lockingPost, powderCoated, rivetCount } = this.definition;
    return {
      DRingType,
      collarWidth: primaryWidth,
      lockingPost,
      powderCoated: powderCoated || 'red',
      rivetCount,
    }
  }

  public getLinerOptions(): LeatherColors[] | null {
    if (startsWith(this.definition.primaryMaterial, "GatorStrap")) {
      return null;
    }

    const deer = getLeather('Deer');
    const buffalo = getLeather('Buffalo');
    const rabbitFur = getLeather('Rabbit Fur');
    const fursuit = getLeather('FURSUIT');

    return [
      deer,
      { ...buffalo, colors: buffalo.colors.filter(c => c.name === 'black') },
      rabbitFur,
      fursuit,
    ];
  }

  public getMinStrapSize(): number {
    const { definition } = this;
    var targetMin = 10.0;

    if (definition.DRingType == "3 Ring")
      targetMin = Math.max(targetMin, 11.0);

    if (definition.DRingType == "5 Ring")
      targetMin = Math.max(targetMin, 15.0);

    return targetMin;
  }

  public getLockingOptions(material: CollarMaterial, width: number): LockingPost[] {
    if (startsWith(material, "GatorStrap")) {
      return lockingPosts.filter(t => t.id === "padlockpost");
    }

    if(width < 1.0) {
      return lockingPosts.filter(t => t.id === "padlockpost");
    }

    return lockingPosts;
  }

  public prettyPrint() {
    const { definition } = this;
    var output = this.name + "\n";

    output += `Width: ${definition.primaryWidth}\n`;
    output += `Adjustment Holes: ${definition.holes}\n`;

    if (definition.lockingPost != null) {
      output += `Locking Post: ${definition.lockingPost}\n`;
    }

    output += `Primary Material: ${definition.primaryMaterial}\n`;

    if (definition.primaryStiching != null) {
      output += `Primary Stiching: ${definition.primaryStiching}\n`;
    }

    if (definition.linerMaterial != null) {
      output += `Liner material: ${definition.linerMaterial}\n`;
    }

    if (definition.DRingType != null) {
      output += `D Ring: ${definition.DRingType}\n`;
    }

    if (definition.powderCoated != null) {
      output += `Powder Coat: ${definition.powderCoated}\n`;
    }

    return output;
  }

  abstract getCollarWidthOptions(): number[];
  abstract getPrimaryOptions(): LeatherColors[] | null;
  abstract getPricing(): AbstractStripePricing<T>;
  abstract materialSelection(onUpdate: (def: T) => void): JSX.Element;
  abstract optionsSelection(onUpdate: (def: T) => void): JSX.Element;
  abstract getDrawObject(): AbstractStrapDraw<T>;
}
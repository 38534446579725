import { startsWith } from "lodash";
import { CollarMaterial, DRingTypes, getLeather, getLeatherByName, getLeatherColorByName } from "../options";
import { LeatherColors } from '../settings';
import { AbstractCollarPricing } from "./AbstractPricing";
import { AbstractCollarDefinition } from "./BaseCollarDefinition";
import { LockingPost, lockingPosts } from "../components";
import { AbstractStrapDraw } from "../strap/AbstractDraw";
import { AbstractStrap, fromList } from "../strap/AbstractStrap";

export function checkNameTag(
  definition: Pick<AbstractCollarDefinition, 'nameTag' | 'DRingType' | 'primaryWidth' | 'nameTagValue' | 'primaryMaterial'>
) {
  if (["5 Ring", '3 Ring'].includes(definition.DRingType)) {
    definition.nameTag = null;
  }

  if (definition.primaryWidth < 0.75) {
    definition.nameTag = null;
  }

  if (definition.primaryMaterial.startsWith("GatorStrap")) {
    definition.nameTag = null;
  }

  if (definition.nameTag && definition.nameTagValue && definition.nameTagValue.length > 0) {
    var value = definition.nameTagValue;
    var split = value.split(" ").filter(function (i) { return i.length > 0; });

    if (split.length == 0) {
      definition.nameTagValue = "";
      return;
    }

    split[0] = split[0].substring(0, 8);

    if (split[1] != null) {
      split[1] = split[1].substring(0, 8);
      definition.nameTagValue = split[0] + " " + split[1];
    } else {
      definition.nameTagValue = split[0];

      if (value.charAt(value.length - 1) == " ") {
        definition.nameTagValue = definition.nameTagValue + " ";
      }
    }
  }
}
export abstract class AbstractCollar<T extends AbstractCollarDefinition> extends AbstractStrap<T> {

  public baseCleanup(input: Readonly<T>) {
    return {
      ...super.baseCleanup(input),
      nameTag: input.nameTag || null,
      nameTagValue: input.nameTagValue || null,
    };
  }

  public getMinStrapSize(): number {
    var targetMin = super.getMinStrapSize();

    if (this.definition.nameTag) {
      targetMin = Math.max(targetMin, 15.0);
    }

    return targetMin;
  }

  public prettyPrint(): string {
    const { definition } = this;
    let output = super.prettyPrint();

    output += `Neck size: ${definition.neckSize}\n`;

    if (definition.studDistance > 0) {
      output += `Stud distance: ${definition.studDistance}\n`;
      output += `Stud count: ${definition.studCount}\n`;
    }

    if (definition.nameTag != null) {
      output += `Name Tag: Type ${definition.nameTag}\n`;
      output += `Name Tag Value: ${definition.nameTagValue}\n`;
    }

    return output;
  }

  abstract getPricing(): AbstractCollarPricing<T>;
}

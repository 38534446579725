import { splitColor } from "../../strap/AbstractPricing";
import { AbstractCollarPricing, PowderCoatingPrice } from "../AbstractPricing";
import { AccentStrapCollarDefinition } from "./Collar";

export class AccentStrapCollarPricing extends AbstractCollarPricing<AccentStrapCollarDefinition> {

  public getTotal(def: AccentStrapCollarDefinition): number {
    let total = 0;
    total += this.getDRing(def.primaryWidth, def.DRingType);
    total += this.getLinerPricing(def);
    return super.getTotal(def) + total;
  }

  public getBasePrice() {
    return 55;
  }

  public getPowderedCoating(input: PowderCoatingPrice) {
    return super.getPowderedCoating(input) + 4;
  }

  public getLinerPricing(def: AccentStrapCollarDefinition) {
    const primary = splitColor(def.primaryMaterial);

    // Charge for every additional inch with neck sizes bigger than 17" 
    let extraNeckSize = (def.neckSize - 17);
    let additional = 0;

    if (extraNeckSize <= 0) {
      extraNeckSize = 0;
    }
    if (primary.fabric != "GatorStrap") {
      if (def.primaryWidth == 1.0) { additional += extraNeckSize * 4; }
      if (def.primaryWidth == 1.25) { additional += extraNeckSize * 7; }
      if (def.primaryWidth == 1.5) { additional += extraNeckSize * 10; }
      if (def.primaryWidth == 2.0) { additional += extraNeckSize * 15; }
    } else {
      if (def.primaryWidth == 1) { additional += extraNeckSize * 2; }
      if (def.primaryWidth == 1.5) { additional += extraNeckSize * 3; }
      if (def.primaryWidth == 2) { additional += extraNeckSize * 5; }
    }

    return additional;
  }

  public materialCost(def: AccentStrapCollarDefinition) {
    const primary = splitColor(def.primaryMaterial);
    let additional = 0.0;

    // Spreadsheet 20-23
    if (primary.fabric != "GatorStrap") {
      if (def.primaryWidth == 1) {
      additional += 20;
      }
      else if (def.primaryWidth == 1.25) {
        if (def.accentWidth == 0.5) { additional += 55; }
        if (def.accentWidth == 0.625) { additional += 55; }
        if (def.accentWidth == 0.75) { additional += 55; }
        if (def.accentWidth == 1) { additional += 65; }
      }
      else if (def.primaryWidth == 1.5) {
        if (def.accentWidth == 0.5) { additional += 65; }
        if (def.accentWidth == 0.625) { additional += 65; }
        if (def.accentWidth == 0.75) { additional += 65; }
        if (def.accentWidth == 1) { additional += 85; }
        if (def.accentWidth == 1.25) { additional += 100; }
      }
      else if (def.primaryWidth == 2) {
        if (def.accentWidth == 0.5) { additional += 95; }
        if (def.accentWidth == 0.625) { additional += 95; }
        if (def.accentWidth == 0.75) { additional += 95; }
        if (def.accentWidth == 1) { additional += 115; }
        if (def.accentWidth == 1.25) { additional += 125; }
        if (def.accentWidth == 1.5) { additional += 135; }
      }
    }
    
    if (primary.fabric == "Latigo") {
      const liner = splitColor(def.linerMaterial);
      if (liner.fabric == "Rabbit Fur"){
        if (def.primaryWidth == 1.0) {
          additional += 35.0; 
        } else if (def.primaryWidth == 1.25) {
          additional += 45.0; 
        } else if (def.primaryWidth == 1.5) {
          additional += 55.0; 
        } else {
          additional += 75.0; 
        }
      }
    }

    // Spreadsheet 25-27
    if (primary.fabric == "GatorStrap") {
      if (def.primaryWidth == 1.0) {
        additional += 0.0;
      } else if (def.primaryWidth == 1.5) {
       if (def.accentWidth == 0.625) { additional += 20; }
       if (def.accentWidth == 1) { additional += 40; }

      } else if (def.primaryWidth == 2) {
       if (def.accentWidth == 0.625) { additional += 35; }
       if (def.accentWidth == 1) { additional += 55; }
       if (def.accentWidth == 1.5) { additional += 85; }
      }
    }

    return additional;
  }
}

import React from "react";
import { BootstrapSlider } from "./BootstrapSlider";
import Toggle from 'react-bootstrap-toggle';

interface CuffSliderProps {
  minSize: number;
  size: number;
  title: string;
  onUpdate(value: number): void;
}

export const CuffSlider: React.FC<CuffSliderProps> = props => {
  const { size, onUpdate, title } = props;

  return <div style={{ display: 'flex' }}>
    <div style={{ flex: '0 0 72px' }}>
      <Toggle
        onClick={v => onUpdate(v ? 8 : 0)}
        active={size > 0}
      />
    </div>
    <div style={{ flex: 1, paddingLeft: '30px' }} >
      <div className="form-group" >
        <div style={{ fontSize: "21px" }}>{title}</div>

        {size > 0 && <CuffSliderInner {...props} />}
      </div>
    </div>
  </div>;
}

const CuffSliderInner: React.FC<CuffSliderProps> = props => {
  const { size, minSize, onUpdate, title } = props;

  return <>
    <label className="control-label col-sm-3">
      Size ({size}")
    </label>
    <div className="col-sm-9">
      <div className="checkbox">
        <BootstrapSlider
          step={0.5}
          minSize={minSize}
          maxSize={30}
          size={size}
          onUpdate={onUpdate}
          formatter={val => `${val}"`}
        />
      </div>
    </div>
  </>;
};

import React from 'react';
import Toggle from 'react-bootstrap-toggle';
import { powderCoatedList } from '../settings';
import numeral from 'numeral';

interface PowderCoatedProps {
  onUpdate(value: string | null): void;
  value: string | null;
  price: number;
}

export const PowderCoated: React.FC<PowderCoatedProps> = props => {
  const { value, onUpdate, price } = props;

  return <tr>
    <td style={{ verticalAlign: 'middle' }}>
      <Toggle onClick={v => onUpdate(v ? "Black" : null)} active={value !== null} />
    </td>
    <td>
      <div style={{ fontSize: "21px" }}>Add Powder Coated Metal Hardware</div>
      <small>Tough polymer plastic coating applied and baked over all metal parts. WARNING: This option will add 4 to 6 weeks to production time.</small>
      {value !== null && <PowderCoatedOptions {...props} />}
    </td>
    <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
      <h3 style={{ margin: '0px' }}>{numeral(price).format('$0,0.00')}</h3>
    </td>
  </tr>;
}


const PowderCoatedOptions: React.FC<PowderCoatedProps> = props => {
    const { value, onUpdate } = props;

  return <div>
    Choose powder coat:
    <select name="powderCoatedSelect" value={value} onChange={e => onUpdate(e.target.value)}>
      {powderCoatedList.map(entry => {
        return <option key={entry.name} value={entry.name}>{entry.name}</option>;
      })}
    </select>
  </div>;
}

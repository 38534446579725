import { BaseCollarObject } from "./BaseCollarObject";
import * as options from '../options';
import { getImage } from "./image";


export class ImageStud extends BaseCollarObject<{ type: string }> {
  get data() {
    let lst = options.studArray.filter(t => t.group + " " + t.name === this.opts.type);
    return lst[0];
  }

  drawOverlay(ctx) {
    let data = this.data;

    if (!data)
      return;

    let image = getImage("image/studs/" + data.topimage);

    if (image.width === 0)
      return;

    var size = data.size;
    ctx.drawImage(image, 0, 0, image.width, image.height, -size / 2, -size / 2, size, size);
  }
}

import { BaseCollarStrapDraw, BuckleDraw, PermanenteEdge, RivetDraw } from "../../drawing";
import { threadColors } from "../../settings";
import { AbstractCollarDraw } from "../AbstractDraw";
import { PermanenteCollarDefinition } from "./Collar";


export class PermanenteCollarDraw extends AbstractCollarDraw<PermanenteCollarDefinition> {

  get extraSpacingOnEnd(): number {
    return -1.5;
  }

  constructor(opts: PermanenteCollarDefinition) {
    super(opts);
    this.addDRing(this.objs, 0, false);

    this.objs.push(new BaseCollarStrapDraw({
      color: this.getLeatherColorByName(opts.primaryMaterial),
      x: 0,
      y: 0,
      w: this.topStrapSize,
      h: this.primaryWidth,
      isTopStrap: true,
      addRightRounded: false,
      seamColor: this.getColorByName(threadColors, opts.primaryStiching)
    }));

    this.objs.push(new PermanenteEdge({
      h: this.primaryWidth,
      x: 0.75,
      y: 0,
    }));
  }

  public addRivet(objs, xPos) {
    let rivetCount = this.primaryWidth >= 1.0 ? 2 : 1;

    let step = this.primaryWidth / rivetCount;
    for (let y = -this.primaryWidth / 2 + step / 2; y < this.primaryWidth / 2; y += step) {
      objs.push(new RivetDraw({
        x: xPos,
        y: y,
        color: this.metalColor,
        sexBolt: true,
        size: 12 / 64,
      }));
    }
  }
}

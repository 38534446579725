interface NameTagOptions {
  name: string;
  image: string;
  width: number;
  height: number;
}

export const nameTagOptions: NameTagOptions[] = [
  {
    name: "Plain",
    image: "image/charmletter/name-plain.png",
    width: 783,
    height: 156
  },
  {
    name: "Rhinestones",
    image: "image/charmletter/name-rhinestones.png",
    width: 806,
    height: 162
  }
];

export const DRingTypes: string[] = [
  "Front",
  "Pet",
  "Front+Pet",
  "3 Ring",
  "5 Ring"
];

export interface LeatherColor {
  name: string;
  color?: string;
  image?: string;
  sizes?: number[];
}

export interface LeatherColors {
  name: string;
  description: string;
  folder?: string;
  colors: LeatherColor[];
}

export const leatherColors: LeatherColors[] = [
  {
    name: "Latigo",
    description: "Latigo leather is the best choice for leather straps that hold a buckle, including collars, cuffs, and belts. It's strong, doesn't stretch, and is available in more than a dozen colors. Because it's rigid, it is not as soft or flexible as other materials.",
    colors: [
      {
        name: "black",
        color: "#000000"
      },
      {
        name: "red",
        color: "#cc0000"
      },
      {
        name: "chocolate brown",
        color: "#201510"
      },
      {
        name: "cognac",
        color: "#402328"
      },
      {
        name: "burgundy",
        color: "#461b24"
      },      
      {
        name: "honey brown",
        color: "#945e18"
      },
      {
        name: "orange",
        color: "#e75c00"
      },
      {
        name: "yellow",
        color: "#e9ec00"
      },
      {
        name: "green",
        color: "#028123"
      },
      {
        name: "teal",
        color: "#009297"
      },
      {
        name: "royal blue",
        color: "#0e0aad"
      },
      {
        name: "sky blue",
        color: "#618eff"
      },
      {
        name: "purple",
        color: "#2f005b"
      },
      {
        name: "lavender",
        color: "#ce7aff"
      },
      {
        name: "hot pink",
        color: "#ff7ad2"
      },
      {
        name: "white",
        color: "#ffffff"
      }
    ]
  },
  {
    name: "Buffalo",
    description: "Buffalo leather is ideal for collar and cuff pad straps, and since it's designed for use as moccasin leather too, it makes great coasters. It's flexible, soft, durable, has a beautiful pattern, and is very comfortable directly on bare skin. As an ANALINE hide, it's dyed and NOT pigmented. This means when it gets wet, it will bleed color onto other surfaces. Do not choose Buffalo as a liner for items to be worn on fursuits (but it can be used as a pad with a white chap liner).",
    colors: [
      {
        name: "black",
        color: "#000000"
      },
      {
        name: "white",
        color: "#eaeaea"
      },
      {
        name: "chocolate brown",
        color: "#201510"
      },
      {
        name: "redwood",
        color: "#520b1b"
      },
      {
        name: "honey brown",
        color: "#945e18"
      }
    ]
  },
  {
    name: "Bullhide",
    description: "Bullhide is similar to Buffalo, but is available in many more colors. It's slightly waxy, and is a semi-analine hide (meaning it has both dye and pigment to make it's top color). It's flexible, soft, durable, has a beautiful pattern. Due to the wax used during tanning, it is not suitable for prolonged direct skin contact, and is therefore not available as a liner material.",
    colors: [
      {
        name: "red",
        color: "#cc0000"
      },
      {
        name: "tobacco brown",
        color: "#874f05"
      },
      {
        name: "green",
        color: "#02623d"
      },
      {
        name: "teal",
        color: "#1694a7"
      },
      {
        name: "navy blue",
        color: "#2c2a35"
      },
      {
        name: "cobalt blue",
        color: "#3e74ba"
      },
      {
        name: "purple",
        color: "#340a7a"
      },
      {
        name: "hot pink",
        color: "#e645b0"
      },
      {
        name: "bone",
        color: "#ffffdc"
      },
      {
        name: "dirty white",
        color: "#dbcebc"
      }
    ]
  },
  {
    name: "Chap",
    description: "Chap leather is the only choice Lagarto offers for leather leashes and harnesses. It's a pigmented leather, meaning the top color surface is artificial and designed to be durable. FYI: this same type of surface is standard for automobile upholstery and sofas. Chap leather can also be used for buckled straps on collars/cuffs if it is doubled (single layer is weak, double layer is as strong as latigo), or used as a pad strap that will have a liner installed. It's thin, flexible, soft, and available in over 50 colors. The edges of this chap will be gray, only the black chap is edge dyed after cutting.",
    colors: [
      {
        name: "black",
        color: "#000000"
      },
      {
        name: "red",
        color: "#cc0000"
      },
      {
        name: "chocolate brown",
        color: "#201510"
      },
      {
        name: "brown",
        color: "#622d15"
      },
      {
        name: "honey brown",
        color: "#945e18"
      },
      {
        name: "orange",
        color: "#ff6600"
      },
      {
        name: "yellow",
        color: "#e9ec00"
      },
      {
        name: "forest green",
        color: "#182416"
      },
      {
        name: "kelly green",
        color: "#028123"
      },
      {
        name: "lawn green",
        color: "#1fbe05"
      },
      {
        name: "lime green",
        color: "#96e637"
      },
      {
        name: "teal",
        color: "#009297"
      },
      {
        name: "royal blue",
        color: "#0e0aad"
      },
      {
        name: "baby blue",
        color: "#aceaf9"
      },
      {
        name: "purple",
        color: "#2f005b"
      },
      {
        name: "hot pink",
        color: "#ff7ad2"
      },
      {
        name: "flesh pink",
        color: "#eabfbf"
      },
      {
        name: "gray",
        color: "#707077"
      },
      {
        name: "white",
        color: "#ffffff"
      }
    ]
  },
  {
    name: "Deer",
    description: "Deerhide is like a very thin version of buffalo. Available in a dozen colors, it is available as a liner only for collars and cuffs. It's flexible, soft, durable, has a beautiful pattern, and is very comfortable directly on bare skin. As an ANALINE hide, it's dyed and NOT pigmented. This means when it gets wet, it will bleed color onto other surfaces. Do not choose deerhide as a liner for items to be worn on fursuits.",
    colors: [
      {
        name: "black",
        color: "#000000"
      },
      {
        name: "red",
        color: "#cc0000"
      },
      {
        name: "dark brown",
        color: "#201510"
      },
      {
        name: "brown",
        color: "#3b2015"
      },
      {
        name: "cork (orange brown)",
        color: "#592108"
      },
      {
        name: "burnt orange",
        color: "#8e3500"
      },
      {
        name: "gold",
        color: "#e9ec00"
      },
      {
        name: "forest green",
        color: "#028123"
      },
      {
        name: "turquoise",
        color: "#009297"
      },
      {
        name: "royal blue",
        color: "#0e0aad"
      },
      {
        name: "purple",
        color: "#2f005b"
      },
      {
        name: "hot pink",
        color: "#ff7ad2"
      },
      {
        name: "gray",
        color: "#242428"
      },
      {
        name: "white",
        color: "#ffffff"
      }
    ]
  },
  {
    name: "Rabbit Fur",
    description: "Rabbit Fur is a byproduct of the food industry. Spaniards in particular eat rabbit like Americans eat chicken. Rabbit skins are very thin, and the fur is both soft and plush. Lagarto uses rabbit fur as a collar or cuff liner (and on other projects by special order). While it is beautiful and comfortable, it does take a lot more work to maintain. Fur is warm, so if your body runs hot, be aware it may cause you to perspire excessively. When dyed fur gets wet, it will bleed color so do not choose rabbit fur as a liner for items to be worn on fursuits - unless it's the bleached white rabbit fur.",
    "folder": "rabbitcolors",
    colors: [
      {
        name: "Black",
        image: "Black.jpg"
      },
      {
        name: "Dark Red",
        image: "Dark Red.jpg"
      },
      {
        name: "Red",
        image: "Red.jpg"
      },
      {
        name: "UV Red",
        image: "UV Red.jpg"
      },
      {
        name: "Dark Brown",
        image: "Dark Brown.jpg"
      },
      {
        name: "Multicolor Brown",
        image: "Multicolor Brown.jpg"
      },
      {
        name: "Cognac",
        image: "Cognac.jpg"
      },
      {
        name: "Orange",
        image: "Orange.jpg"
      },
      {
        name: "UV Orange",
        image: "UV Orange.jpg"
      },
      {
        name: "Yellow",
        image: "Yellow.jpg"
      },
      {
        name: "UV Yellow",
        image: "UV Yellow.jpg"
      },
      {
        name: "Forest Green",
        image: "Forest Green.jpg"
      },
      {
        name: "Green",
        image: "Green.jpg"
      },
      {
        name: "Sage Green",
        image: "Sage Green.jpg"
      },
      {
        name: "UV Green",
        image: "UV Green.jpg"
      },
      {
        name: "Navy Blue",
        image: "Navy Blue.jpg"
      },
      {
        name: "Royal Blue",
        image: "Royal Blue.jpg"
      },
      {
        name: "Electric Blue",
        image: "Electric Blue.jpg"
      },
      {
        name: "Sky Blue",
        image: "Sky Blue.jpg"
      },
      {
        name: "Baby Blue (darker)",
        image: "Baby Blue (darker).jpg"
      },
      {
        name: "Baby Blue",
        image: "Baby Blue.jpg"
      },
      {
        name: "Plum",
        image: "Plum.jpg"
      },
      {
        name: "Purple",
        image: "Purple.jpg"
      },
      {
        name: "Purple (reddish)",
        image: "Purple (reddish).jpg"
      },
      {
        name: "Lavender",
        image: "Lavender.jpg"
      },
      {
        name: "Fuchsia",
        image: "Fuchsia.jpg"
      },
      {
        name: "UV Pink",
        image: "UV Pink.jpg"
      },
      {
        name: "Hot Pink",
        image: "Hot Pink.jpg"
      },
      {
        name: "Flesh Pink",
        image: "Flesh Pink.jpg"
      },
      {
        name: "Gray",
        image: "Gray.jpg"
      },
      {
        name: "White",
        image: "White.jpg"
      },
      {
        name: "Bobcat Print",
        image: "Bobcat Print.jpg"
      },
      {
        name: "Cheetah Print",
        image: "Cheetah Print.jpg"
      },
      {
        name: "Leopard Print",
        image: "Leopard Print.jpg"
      },
      {
        name: "Tiger Print",
        image: "Tiger Print.jpg"
      },
      {
        name: "Zebra Print",
        image: "Zebra Print.jpg"
      }
    ]
  },
  {
    name: "Wool",
    description: "Wool fur is a byproduct of the food industry. Sheep skins are soft and flexible, and the fur is thick and slightly rigid. Lagarto uses wool fur as a cuff liner and on other projects by special order. While it is beautiful, wool is much more scratchy than rabbit fur and therefore not recommended on collars unless you know you can enjoy that feeling. Wool is easy to maintain. Fur is warm, so if your body runs hot, be aware it may cause you to perspire excessively. When dyed wool gets wet, it will bleed color so do not choose wool (except white or cream) as a liner for items to be worn on fursuits.",
    "folder": "wool",
    colors: [
      {
        name: "Black .5\"",
        image: "Black .5 inch.jpg"
      },
      {
        name: "Dark Brown .5\"",
        image: "Dark Brown .5 inch.jpg"
      },
      {
        name: "Mink .5\"",
        image: "Mink .5 inch.jpg"
      },
      {
        name: "Beige .5\"",
        image: "Beige .5 inch.jpg"
      },
      {
        name: "Natural .5\"",
        image: "Natural .5 inch.jpg"
      },
      {
        name: "White .5\"",
        image: "White .5 inch.jpg"
      },
      {
        name: "Black .625\"",
        image: "Black .625 inch.jpg"
      },
      {
        name: "Gray .625\"",
        image: "Gray .625 inch.jpg"
      },
      {
        name: "Palomino .625\"",
        image: "Palomino .625 inch.jpg"
      },
      {
        name: "Steel Blue 1\"",
        image: "Steel Blue 1 inch.jpg"
      },
      {
        name: "Gray 1\"",
        image: "Gray 1 inch.jpg"
      },
      {
        name: "Burgundy 1\"",
        image: "Burgundy 1 inch.jpg"
      },
      {
        name: "Bleached White 1\"",
        image: "Bleached White 1 inch.jpg"
      },
      {
        name: "Bleached White 3\"",
        image: "Bleached White 3 inch.jpg"
      }
    ]
  },
  {
    name: "GatorStrap",
    description: "GatorStrap™ is a 100% synthetic material that you can fully immerse in water. It's waterproof, flexible, bodily fluids can be washed off of it, and it is available in 23 colors including 4 UV reactive colors. The surface is PVC, and the core is polyester webbing. While it is stronger than latigo leather, the surface can be damaged by scrapes against hard & rough surfaces. PVC is not microbe resistant.",
    colors: [
      {
        name: "Black",
        color: "#000000",
        sizes: [0.625, 1, 1.5, 2.0]
      },
      {
        name: "Burgundy",
        color: "#621328",
        sizes: [0.625, 1]
      },
      {
        name: "Red",
        color: "#cc0000",
        sizes: [0.625, 1, 1.5, 2.0]
      },
      {
        name: "Chocolate Brown",
        color: "#3b271d",
        sizes: [0.625, 1, 1.5, 2.0]
      },
      {
        name: "Cognac Reddish Brown",
        color: "#561400",
        sizes: [0.625, 1]
      },
      {
        name: "Honey Brown",
        color: "#945e18",
        sizes: [0.625, 1]
      },
      {
        name: "Orange (Road Cone UV Reactive)",
        color: "#ff6600",
        sizes: [0.625, 1, 1.5, 2.0]
      },
      {
        name: "Orange (lighter UV Reactive)",
        color: "#ff9e43",
        sizes: [0.625, 1]
      },
      {
        name: "Yellow (Sunflower)",
        color: "#f2e526",
        sizes: [0.625, 1]
      },
      {
        name: "Yellow (UV Reactive)",
        color: "#f0ff00",
        sizes: [0.625, 1, 1.5]
      },
      {
        name: "Forest Green",
        color: "#293d25",
        sizes: [0.625, 1]
      },
      {
        name: "Green",
        color: "#028123",
        sizes: [0.625, 1]
      },
      {
        name: "Green (UV Reactive)",
        color: "#00ea16",
        sizes: [0.625, 1, 1.5]
      },
      {
        name: "Teal",
        color: "#009297",
        sizes: [0.625, 1, 1.5]
      },
      {
        name: "Royal Blue",
        color: "#0e0aad",
        sizes: [0.625, 1, 1.5]
      },
      {
        name: "Sky Blue",
        color: "#618eff",
        sizes: [0.625, 1]
      },
      {
        name: "Baby Blue",
        color: "#a4daff",
        sizes: [0.625, 1]
      },
      {
        name: "Purple",
        color: "#2f005b",
        sizes: [0.625, 1, 1.5]
      },
      {
        name: "Pink (Darker UV Reactive)",
        color: "#d30092",
        sizes: [0.625, 1]
      },
      {
        name: "Pink (UV Reactive)",
        color: "#ff2fb9",
        sizes: [0.625, 1, 1.5]
      },
      {
        name: "Gray",
        color: "#4d4d4d",
        sizes: [0.625, 1]
      },
      {
        name: "White",
        color: "#e5e5e5",
        sizes: [0.625, 1, 1.5, 2.0]
      }
    ]
  },
  {
    name: "FURSUIT",
    description: "Lagarto HIGHLY recommends GatorStrap collars for fursuits, not leather. Why? No possibility of staining, washable after perspiration, less expensive, more durable. If you MUST have leather for your fursuit... To protect your fursuit from dye stains, choose ONLY this option - white chap leather. It's a flexible white leather with white pigment (artificial substance, like plastic) that will never stain or damage your polyester fur.",
    colors: [
      {
        name: "White",
        color: "#FFFFFF"
      }
    ]
  }
]


export const threadColors: { name: string; color: string; }[] = [
  {
    name: "black",
    color: "#1a1a1a"
  },
  {
    name: "dark gray",
    color: "#333333"
  },
  {
    name: "gray",
    color: "#4d4d4d"
  },
  {
    name: "red",
    color: "#e50000"
  },
  {
    name: "chocolate brown",
    color: "#3b271d"
  },
  {
    name: "henna",
    color: "#862c18"
  },
  {
    name: "orange",
    color: "#e55c00"
  },
  {
    name: "yellow",
    color: "#ffd800"
  },
  {
    name: "forest green",
    color: "#293d25"
  },
  {
    name: "kelly green",
    color: "#0d7148"
  },
  {
    name: "sage green",
    color: "#3ca24b"
  },
  {
    name: "teal frost",
    color: "#6ed5ca"
  },
  {
    name: "electric bluegreen",
    color: "#0070c6"
  },
  {
    name: "blue gray",
    color: "#1a354a"
  },
  {
    name: "royal blue",
    color: "#0b0994"
  },
  {
    name: "sky blue",
    color: "#3667e2"
  },
  {
    name: "baby blue",
    color: "#accef9"
  },
  {
    name: "fuchsia",
    color: "#d7189f"
  },
  {
    name: "light purple",
    color: "#9b0bb5"
  },
  {
    name: "UV reactive pink",
    color: "#ff2fb9"
  },
  {
    name: "salmon pink",
    color: "#ff7c92"
  },
  {
    name: "silver",
    color: "#dbdbdb"
  },
  {
    name: "white",
    color: "#e5e5e5"
  }
];

export const powderCoatedList: { name: string; color: string }[] = [
  { name: 'Black', color: '#0a0a0a' },
  { name: 'Metallic Red', color: '#cc3333' },
  { name: 'UV Orange', color: '#ff6600' },
  { name: 'Yellow', color: '#ffff00' },
  { name: 'Metallic Green', color: '#009900' },
  { name: 'Cobalt Blue', color: '#003ffa' },
  { name: 'Baby Blue', color: '#aee3ff' },
  { name: 'Metallic Plum (Dark Purple)', color: '#330066' },
  { name: 'Metallic Purple', color: '#9900ff' },
  { name: 'UV Pink', color: '#ff33ff' },
  { name: 'White', color: '#e7e7e7' }
];

export interface StudDefinition {
  group: string;
  price: number;
  sideimage: string;
  size: number;
  topimage: string;
  name: string;
}

export const studs: StudDefinition[] = [
  {
    name: "Claw Stud 1/2 inch Cone Dome",
    group: "Claw Back Studs",
    topimage: "ClawStudConeDome.png",
    sideimage: "ClawStudConeDome5Side.png",
    size: 0.5,
    price: 2
  },
  {
    name: "Claw Stud Bullet Dome Short",
    group: "Claw Back Studs",
    topimage: "ClawStudBulletDome.png",
    sideimage: "ClawStudBulletDomeShortSide.png",
    size: 0.4375,
    price: 2
  },
  {
    name: "Claw Stud Bullet Dome Tall",
    group: "Claw Back Studs",
    topimage: "ClawStudBulletDome.png",
    sideimage: "ClawStudBulletDomeTallSide.png",
    size: 0.426,
    price: 2
  },
  {
    name: "Claw Stud Chain Diamond",
    group: "Claw Back Studs",
    topimage: "ClawStudChainDiamond.png",
    sideimage: "ClawStudChainDiamond.png",
    size: 0.6,
    price: 2
  },
  {
    name: "Claw Stud Chain Oval",
    group: "Claw Back Studs",
    topimage: "ClawStudChainOval.png",
    sideimage: "ClawStudChainOvalSide.png",
    size: 0.512,
    price: 2
  },
  {
    name: "Claw Stud Chain Square",
    group: "Claw Back Studs",
    topimage: "ClawStudChainSquare.png",
    sideimage: "ClawStudChainSquareSide.png",
    size: 0.47,
    price: 2
  },
  {
    name: "Claw Stud 3/8 inch Cone Dome",
    group: "Claw Back Studs",
    topimage: "ClawStudConeDome.png",
    sideimage: "ClawStudConeDome375Side.png",
    size: 0.375,
    price: 2
  },
  {
    name: "Claw Stud 3/8 inch Black Cone Dome",
    group: "Claw Back Studs",
    topimage: "ClawStudConeDome375Black.png",
    sideimage: "ClawStudConeDome375BlackSide.png",
    size: 0.375,
    price: 2
  },
  {
    name: "Claw Stud Elongated Pyramid",
    group: "Claw Back Studs",
    topimage: "ClawStudElongatedPyramid.png",
    sideimage: "ClawStudElongatedPyramidSide.png",
    size: 0.35,
    price: 2
  },
  {
    name: "Claw Stud 1/2 inch Flat Head Disc",
    group: "Claw Back Studs",
    topimage: "ClawStudFlatHeadDisc.png",
    sideimage: "ClawStudFlatHeadDisc5Side.png",
    size: 0.5,
    price: 2
  },
  {
    name: "Claw Stud 3/8 inch Flat Head Disc",
    group: "Claw Back Studs",
    topimage: "ClawStudFlatHeadDisc.png",
    sideimage: "ClawStudFlatHeadDisc375Side.png",
    size: 0.375,
    price: 2
  },
  {
    name: "Claw Stud 1/4 inch Flat Head Disc",
    group: "Claw Back Studs",
    topimage: "ClawStudFlatHeadDisc.png",
    sideimage: "ClawStudFlatHeadDisc25Side.png",
    size: 0.25,
    price: 2
  },
  {
    name: "Claw Stud 5/8 inch Black Pyramid",
    group: "Claw Back Studs",
    topimage: "ClawStudPyramid625Black.png",
    sideimage: "ClawStudPyramid625BlackSide.png",
    size: 0.63,
    price: 2
  },
  {
    name: "Claw Stud 1/2 inch Pyramid",
    group: "Claw Back Studs",
    topimage: "ClawStudPyramid.png",
    sideimage: "ClawStudPyramid5Side.png",
    size: 0.5075,
    price: 2
  },
  {
    name: "Claw Stud 1/2 inch Blue Pyramid",
    group: "Claw Back Studs",
    topimage: "ClawStudPyramid5Blue.png",
    sideimage: "ClawStudPyramid5BlueSide.png",
    size: 0.5075,
    price: 2
  },
  {
    name: "Claw Stud 3/8 inch Pyramid",
    group: "Claw Back Studs",
    topimage: "ClawStudPyramid.png",
    sideimage: "ClawStudPyramid375Side.png",
    size: 0.3965,
    price: 2
  },
  {
    name: "Claw Stud 1/4 inch Pyramid",
    group: "Claw Back Studs",
    topimage: "ClawStudPyramid.png",
    sideimage: "ClawStudPyramid25Side.png",
    size: 0.28,
    price: 2
  },
  {
    name: "Claw Stud 3/16 inch Pyramid",
    group: "Claw Back Studs",
    topimage: "ClawStudPyramid.png",
    sideimage: "ClawStudPyramid1875Side.png",
    size: 0.214,
    price: 2
  },
  {
    name: "1/4 inch interior diameter Eyelet",
    group: "Decorations",
    topimage: "Eyelet25.png",
    sideimage: "Eyelet25Side.png",
    size: 0.49,
    price: 2
  },
  {
    name: "3/8 inch Standard Cap Rivet",
    group: "Decorations",
    topimage: "Rivet375.png",
    sideimage: "Rivet375Side.png",
    size: 0.355,
    price: 1
  },
  {
    name: "3/8 inch Standard Cap Brass Rivet",
    group: "Decorations",
    topimage: "Rivet375Brass.png",
    sideimage: "Rivet375BrassSide.png",
    size: 0.355,
    price: 1
  },
  {
    name: "1/2 inch Bell",
    group: "Decorations",
    topimage: "RivetBell.png",
    sideimage: "RivetBell5Side.png",
    size: 0.5,
    price: 2
  },
  {
    name: "3/4 inch Bell",
    group: "Decorations",
    topimage: "RivetBell.png",
    sideimage: "RivetBell5Side.png",
    size: 0.75,
    price: 2
  },
  {
    name: "1 inch Bell",
    group: "Decorations",
    topimage: "RivetBell.png",
    sideimage: "RivetBell1Side.png",
    size: 1,
    price: 2.5
  },
  {
    name: "1 1/4 inch Bell",
    group: "Decorations",
    topimage: "RivetBell.png",
    sideimage: "RivetBell1Side.png",
    size: 1.25,
    price: 3
  },
  {
    name: "1 1/2 inch Bell",
    group: "Decorations",
    topimage: "RivetBell.png",
    sideimage: "RivetBell1Side.png",
    size: 1.5,
    price: 3.5
  },
  {
    name: "5/8 inch wide Diamond",
    group: "Rivet Back Studs",
    topimage: "RivetDiamondLarge.png",
    sideimage: "RivetDiamondLargeSide.png",
    size: 0.46,
    price: 3
  },
  {
    name: "7/16 inch wide Diamond",
    group: "Rivet Back Studs",
    topimage: "RivetDiamondLarge.png",
    sideimage: "RivetDiamondLargeSide.png",
    size: 0.36,
    price: 3
  },
  {
    name: "9/16 inch wide Dome",
    group: "Rivet Back Studs",
    topimage: "RivetDome10.png",
    sideimage: "RivetDome15Side.png",
    size: 0.593,
    price: 3
  },
  {
    name: "1/2 inch wide Dome",
    group: "Rivet Back Studs",
    topimage: "RivetDome10.png",
    sideimage: "RivetDome12Side.png",
    size: 0.473,
    price: 2
  },
  {
    name: "3/8 inch wide Dome",
    group: "Rivet Back Studs",
    topimage: "RivetDome10.png",
    sideimage: "RivetDome10Side.png",
    size: 0.378,
    price: 2
  },
  {
    name: "Blue Paw",
    group: "Rivet Back Studs",
    topimage: "RivetPawBlue.png",
    sideimage: "RivetPawBlueSide.png",
    size: 0.4355,
    price: 5
  },
  {
    name: "Red Paw",
    group: "Rivet Back Studs",
    topimage: "RivetPawRed.png",
    sideimage: "RivetPawRedSide.png",
    size: 0.4355,
    price: 5
  },
  {
    name: "Toony Paw",
    group: "Rivet Back Studs",
    topimage: "RivetPawToony.png",
    sideimage: "RivetPawToonySide.png",
    size: 0.6565,
    price: 4
  },
  {
    name: "Toony Bone",
    group: "Rivet Back Studs",
    topimage: "RivetBoneToony.png",
    sideimage: "RivetBoneToonySide.png",
    size: 0.558,
    price: 4
  },
  {
    name: "1/2 inch Brass Pyramids",
    group: "Rivet Back Studs",
    topimage: "RivetPyramid5Brass.png",
    sideimage: "RivetPyramid5BrassSide.png",
    size: 0.65,
    price: 2
  },
  {
    name: "1/2 inch Nickel Plated Brass Pyramids",
    group: "Rivet Back Studs",
    topimage: "RivetPyramid5.png",
    sideimage: "RivetPyramid5Side.png",
    size: 0.65,
    price: 2
  },
  {
    name: "Post With Loop",
    group: "Rivet Back Studs",
    topimage: "RivetPostLoop.png",
    sideimage: "RivetPostLoopSide.png",
    size: 1.2,
    price: 5
  },
  {
    name: "1/2 inch tall Dog Collar Spike",
    group: "Rivet Back Spikes",
    topimage: "RivetSpike5.png",
    sideimage: "RivetSpike5Side.png",
    size: 0.31,
    price: 2
  },
  {
    name: "1 1/4 inch tall Dog Collar Spike",
    group: "Rivet Back Spikes",
    topimage: "RivetSpike5.png",
    sideimage: "RivetSpike125Side.png",
    size: 0.37,
    price: 3
  },
  {
    name: "1/2 inch tall Cone Spike",
    group: "Screw Back Spikes",
    topimage: "ScrewSpike5.png",
    sideimage: "ScrewSpike5Side.png",
    size: 0.3875,
    price: 2
  },
  {
    name: "5/8 inch tall Cone Spike",
    group: "Screw Back Spikes",
    topimage: "ScrewSpike5.png",
    sideimage: "ScrewSpike5Side.png",
    size: 0.3775,
    price: 2
  },
  {
    name: "3/4 inch tall Bulldog Cone Spike",
    group: "Screw Back Spikes",
    topimage: "ScrewSpikeBulldog75.png",
    sideimage: "ScrewSpike5Side.png",
    size: 0.592,
    price: 3
  },
  {
    name: "1 inch tall Cone Spike",
    group: "Screw Back Spikes",
    topimage: "ScrewSpike5.png",
    sideimage: "ScrewSpike5Side.png",
    size: 0.3875,
    price: 3
  },
  {
    name: "2 inch tall Cone Spike",
    group: "Screw Back Spikes",
    topimage: "ScrewSpike5.png",
    sideimage: "ScrewSpikeDemonClawSide.png",
    size: 0.3875,
    price: 4
  },
  {
    name: "2 inch tall Thick Demon Spike",
    group: "Screw Back Spikes",
    topimage: "ScrewSpikeCatClaw.png",
    sideimage: "ScrewSpikeDemonClawSide.png",
    size: 0.3875,
    price: 6
  },
  {
    name: "2 inch tall Thin Demon Spike",
    group: "Screw Back Spikes",
    topimage: "ScrewSpikeCatClaw.png",
    sideimage: "ScrewSpikeDemonClawSide.png",
    size: 0.3875,
    price: 6
  },
  {
    name: "1 inch tall Robot Claw Spike",
    group: "Screw Back Spikes",
    topimage: "ScrewSpikeRobotClaw.png",
    sideimage: "ScrewSpikeRobotClawSide.png",
    size: 1.75,
    price: 5
  },
  {
    name: "5/8 inch tall Robot Claw Spike",
    group: "Screw Back Spikes",
    topimage: "ScrewSpikeRobotClaw.png",
    sideimage: "ScrewSpikeRobotClawSide.png",
    size: 1.25,
    price: 5
  },
  {
    name: "1 1/4 inch tall Raptor Talon Spike",
    group: "Screw Back Spikes",
    topimage: "ScrewSpikeCatClaw.png",
    sideimage: "ScrewSpikeRobotClawSide.png",
    size: 0.3875,
    price: 7
  },
  {
    name: "2 3/8 inch tall Cat Claw Spike",
    group: "Screw Back Spikes",
    topimage: "ScrewSpikeCatClaw.png",
    sideimage: "ScrewSpikeCatClawSide.png",
    size: 3,
    price: 12
  },
  {
    name: "2 3/8 inch tall Cat Claw Spike",
    group: "Screw Back Spikes",
    topimage: "ScrewSpikeCatClaw.png",
    sideimage: "ScrewSpikeCatClawSide.png",
    size: 2.5,
    price: 10
  },
  {
    name: "2 3/8 inch tall Cat Claw Spike",
    group: "Screw Back Spikes",
    topimage: "ScrewSpikeCatClaw.png",
    sideimage: "ScrewSpikeCatClawSide.png",
    size: 2,
    price: 4
  },
  {
    name: "2 3/8 inch tall Cat Claw Spike",
    group: "Screw Back Spikes",
    topimage: "ScrewSpikeCatClaw.png",
    sideimage: "ScrewSpikeCatClawSide.png",
    size: 1.5,
    price: 3
  }
];

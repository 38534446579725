import { BaseCollarStrapDraw, BuckleDraw, RingDraw, RivetDraw } from "../../drawing";
import { threadColors } from "../../settings";
import { AbstractCollarDraw } from "../AbstractDraw";
import { MartinGaleCollar, MartinGaleCollarDefinition } from "./Collar";


export class MartinGaleCollarDraw extends AbstractCollarDraw<MartinGaleCollarDefinition> {

  get rightRivetPosition(): number {
    return this.topStrapSize / 2 - 0.75;
  }

  get leftRivetPosition(): number {
    return -this.topStrapSize / 2 + 0.75;
  }


  constructor(opts: MartinGaleCollarDefinition) {
    super(opts);
    if (opts.studDistance > 0) {
      let leftPad = this.leftRivetPosition + 0.5;
      let rightPad = this.rightRivetPosition - 0.5;
      this.addStuds(this.objs, leftPad, rightPad, 0.0);
    }

    this.objs.push(new RingDraw({
      x: this.topStrapSize / 2 + 0.25,
      y: 0,
      size: 1.5,
    }));

    this.objs.push(new RingDraw({
      x: -this.topStrapSize / 2 - 0.25,
      y: 0,
      size: 1.5,
    }));

    this.addRivet(this.objs, this.rightRivetPosition);
    this.addRivet(this.objs, this.leftRivetPosition);

    this.objs.push(new BaseCollarStrapDraw({
      color: this.getLeatherColorByName(opts.primaryMaterial),
      x: 0,
      y: 0,
      w: this.topStrapSize,
      h: this.primaryWidth,
      isTopStrap: true,
      addRightRounded: false,
      seamColor: this.getColorByName(threadColors, opts.primaryStiching)
    }));
  }

  get strapSize() {
    return this.opts.neckSize - 6.5;
  }
}

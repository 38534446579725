import { AbstractCollarPricing, PowderCoatingPrice } from "../AbstractPricing";
import { PermanenteCollarDefinition } from "./Collar";



export class PermanentePricing extends AbstractCollarPricing<PermanenteCollarDefinition> {

  public getTotal(def: PermanenteCollarDefinition): number {
    let total = 0;
    total += this.extraBolts(def.extraBolts);
    total += this.getDRing(def.primaryWidth, def.DRingType);
    total += this.getLinerPricing(def);
    return super.getTotal(def) + total;
  }

  public getBasePrice() {
    return 35;
  }

  public getPowderedCoating(input: PowderCoatingPrice) {
    return 0
  }

  public extraBolts(count: number) {
    return count * 3;
  }

  public materialCost(def: PermanenteCollarDefinition) {
    let additional = 0.0;

   // old pricing code for jumps at 20 and 26 inches
   // if (def.neckSize >= 26) {
   //   additional += 4;
   // } else if (def.neckSize >= 20) {
   //   additional += 2;
   // }

    if(def.primaryWidth === 1) {
      additional += 10;
    }

    return additional;
  }

  public getLinerPricing(def: PermanenteCollarDefinition) {
    // Charge for every additional inch with neck sizes bigger than 17" 
    let extraNeckSize = (def.neckSize - 17);
    let additional = 0;

    if (extraNeckSize <= 0) {
      extraNeckSize = 0;
    }

    additional += extraNeckSize * 1;
    return additional;
  }
}

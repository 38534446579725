import { BaseCollarObject } from "./BaseCollarObject";

interface DRingDrawProps {
  h: number;
  extraSpace?: number;
  bottomLayer?: boolean;
  color?: string;
}

export class DRingDraw extends BaseCollarObject<DRingDrawProps> {

  get h() { return this.opts.h; }
  get extraSpace() {
    if (this.opts.extraSpace)
      return this.opts.extraSpace;

    return this.h / 10.0;
  }

  private drawBackMetalLine(ctx) {
    ctx.strokeStyle = this.opts.color || "rgb(200,200,200)";
    ctx.lineWidth = 0.1;
    ctx.setLineDash([0])

    this.drawLine(ctx, 0, - this.h / 2 - this.extraSpace, 0, this.h / 2 + this.extraSpace);
  }

  drawBack(ctx) {
    if (this.opts.bottomLayer) {
      this.drawBackMetalLine(ctx);
    }
  }

  drawMiddleLayer(ctx) {
    if (!this.opts.bottomLayer) {
      this.drawBackMetalLine(ctx);
    }
  }

  drawOverlay(ctx) {
    this.drawRing(ctx, "rgb(150,150,150)", -0.04);
    this.drawRing(ctx, this.opts.color || "rgb(200,200,200)", 0);
  }

  drawRing(ctx, color, offset) {
    ctx.strokeStyle = color;
    ctx.lineWidth = 0.1;
    ctx.setLineDash([0]);

    var ringSize = this.h * 0.33;

    ctx.beginPath();
    ctx.moveTo(
      offset, - this.h / 2 - this.extraSpace);
    ctx.bezierCurveTo(
      ringSize, - this.h / 2 - this.extraSpace,
      ringSize, this.h / 2 + this.extraSpace,
      offset, this.h / 2 + this.extraSpace
    );
    ctx.stroke();
  }
}
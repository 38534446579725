import { splitColor } from "../../strap/AbstractPricing";
import { AbstractCollarPricing, PowderCoatingPrice } from "../AbstractPricing";
import { PrimaryPlusPadCollarDefinition } from "./Collar";



export class PrimaryPlusPadCollarPricing extends AbstractCollarPricing<PrimaryPlusPadCollarDefinition> {

  public getTotal(def: PrimaryPlusPadCollarDefinition): number {
    let total = 0;

    if (def.padStiching) {
      total += this.getStiching();
    }

    total += this.getLinerPricing(def);
    total += this.getDRing(def.primaryWidth, def.DRingType);
    return super.getTotal(def) + total;
  }

  public getBasePrice() {
    return 55;
  }

  public getPowderedCoating(input: PowderCoatingPrice) {
    return super.getPowderedCoating(input) + 4;
  }

  public getLinerPricing(def: PrimaryPlusPadCollarDefinition) {
    const pad = splitColor(def.padMaterial);

    // Charge for every additional inch with neck sizes bigger than 17" 
    let extraNeckSize = (def.neckSize - 17);
    let additional = 0;

    if (extraNeckSize <= 0) {
      extraNeckSize = 0;
    }

    if (pad.fabric != "GatorStrap") {
      if (def.primaryWidth == 0.5) { additional += extraNeckSize * 5; }
      if (def.primaryWidth == 0.625) { additional += extraNeckSize * 5; }
      if (def.primaryWidth == 0.75) { additional += extraNeckSize * 5; }
      if (def.primaryWidth == 1) { additional += extraNeckSize * 5; }
      if (def.primaryWidth == 1.25) { additional += extraNeckSize * 8; }
      if (def.primaryWidth == 1.5) { additional += extraNeckSize * 10; }
      if (def.primaryWidth == 2) { additional += extraNeckSize * 15; }
      if (def.primaryWidth == 2.5) { additional += extraNeckSize * 20; }
    } else {
      if (def.primaryWidth == 0.625) { additional += extraNeckSize * 2; }
      if (def.primaryWidth == 1.0) { additional += extraNeckSize * 2; }
      if (def.primaryWidth == 1.5) { additional += extraNeckSize * 5; }
    }

    return additional;
  }

  public materialCost(def: PrimaryPlusPadCollarDefinition) {
    const pad = splitColor(def.padMaterial);
    let additional = 0.0;

    // Add extra cost for inside liner (When not "GatorStrap" pad)
    if (pad.fabric != "GatorStrap") {
      // Line 32 to 39 on spreadsheet 
      if (def.primaryWidth == 0.625) {
        if (def.padWidth == 1) { additional += 20; }
        if (def.padWidth == 1.5) { additional += 20; }
        if (def.padWidth == 1.75) { additional += 40; }
        if (def.padWidth == 2) { additional += 45; }
        if (def.padWidth == 2.25) { additional += 80; }
        if (def.padWidth == 2.5) { additional += 90; }
      }
      else if (def.primaryWidth == 1) {
        if (def.padWidth == 1.5) { additional += 20; }
        if (def.padWidth == 1.75) { additional += 40; }
        if (def.padWidth == 2) { additional += 45; }
        if (def.padWidth == 2.25) { additional += 80; }
        if (def.padWidth == 2.5) { additional += 90; }
      }
      else if (def.primaryWidth == 1.25) {
        if (def.padWidth == 1.5) { additional += 45; }
        if (def.padWidth == 1.75) { additional += 65; }
        if (def.padWidth == 2) { additional += 70; }
        if (def.padWidth == 2.25) { additional += 105; }
        if (def.padWidth == 2.5) { additional += 115; }
        if (def.padWidth == 3) { additional += 140; }
      }
      else if (def.primaryWidth == 1.5) {
        if (def.padWidth == 2) { additional += 100; }
        if (def.padWidth == 2.25) { additional += 135; }
        if (def.padWidth == 2.5) { additional += 145; }
        if (def.padWidth == 3) { additional += 170; }
        if (def.padWidth == 3.5) { additional += 195; }
      }
      else if (def.primaryWidth == 2) {
        if (def.padWidth == 2.5) { additional += 175; }
        if (def.padWidth == 3) { additional += 200; }
        if (def.padWidth == 3.5) { additional += 225; }
        if (def.padWidth == 4) { additional += 250; }
        if (def.padWidth == 4.5) { additional += 275; }
      }
      else if (def.primaryWidth == 2.5) {
        if (def.padWidth == 3) { additional += 260; }
        if (def.padWidth == 3.5) { additional += 285; }
        if (def.padWidth == 4) { additional += 310; }
        if (def.padWidth == 4.5) { additional += 335; }
        if (def.padWidth == 5) { additional += 365; }
      }

      // Line 44 on spreadsheet
      const liner = splitColor(def.linerMaterial);
      if (liner.fabric == "Rabbit Fur" || liner.fabric == "Wool") { 
        if (def.padWidth == 1) { additional += 30; }
        if (def.padWidth == 1.5) { additional += 30; }
        if (def.padWidth == 1.75) { additional += 35; }
        if (def.padWidth == 2) { additional += 40; }
        if (def.padWidth == 2.25) { additional += 45; }
        if (def.padWidth == 2.5) { additional += 50; }
        if (def.padWidth == 3) { additional += 60; }
        if (def.padWidth == 3.5) { additional += 70; }
        if (def.padWidth == 4) { additional += 80; }
        if (def.padWidth == 4.5) { additional += 90; }
        if (def.padWidth == 5.0) { additional += 100; }
      }

      // Extra $10 for wool
      if (liner.fabric == "Wool") {
        additional += 10.0;
      }
    }

    // Extra white buffalo costs
    if(pad.fabric == "Buffalo" && pad.color == 'white') {
      if (def.padWidth == 1) { additional += 10; }
      if (def.padWidth == 1.5) { additional += 15; }
      if (def.padWidth == 1.75) { additional += 20; }
      if (def.padWidth == 2) { additional += 20; }
      if (def.padWidth == 2.25) { additional += 25; }
      if (def.padWidth == 2.5) { additional += 25; }
      if (def.padWidth == 3) { additional += 30; }
      if (def.padWidth == 3.5) { additional += 35; }
      if (def.padWidth == 4) { additional += 40; }
      if (def.padWidth == 4.5) { additional += 45; }
      if (def.padWidth == 5) { additional += 50; }
    }

    // Line 41 to 43 on spreadsheet
    if (pad.fabric == "GatorStrap") {
      if (def.padWidth == 2) { 
        if (def.primaryWidth == 1) {
          additional += 15.0;
        } else if (def.primaryWidth == 1.5) {
          additional += 60.0;
        }
      }
    }

    return additional;
  }
}



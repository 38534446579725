import { BaseCollarObject } from "./BaseCollarObject";

interface BaseCollarStrapDrawOpts {
  w: number;
  h: number;
  seamColor?: string;
  edgeRadius?: number;
  seamDist?: number;
  isTopStrap: boolean;
  color: string;
  addRightRounded?: boolean;
}

export class BaseCollarStrapDraw extends BaseCollarObject<BaseCollarStrapDrawOpts> {

  get w() { return this.opts.w; }
  get h() { return this.opts.h; }
  get seamColor() { return this.opts.seamColor || "rgb(255,255,255)"; }
  get edgeRadius() {
    if (this.opts.edgeRadius) {
      return this.opts.edgeRadius;
    }

    if (this.opts.seamColor !== null) {
      return 1 / 4;
    }

    return 0;
  }

  drawBaseStrap(ctx) {
    if (!this.opts.isTopStrap) {
      this.drawStrap(ctx);
    }
  }

  drawTopStrap(ctx) {
    if (this.opts.isTopStrap) {
      this.drawStrap(ctx);
    }
  }

  drawStrap(ctx) {
    ctx.save();
    ctx.fillStyle = this.opts.color;

    if (this.opts.addRightRounded) {
      this.pathRoundedRect2(ctx, -this.w / 2, -this.h / 2, this.w, this.h, this.edgeRadius, 1 / 2);
    } else {
      this.pathRoundedRect(ctx, -this.w / 2, -this.h / 2, this.w, this.h, this.edgeRadius);
    }
    ctx.fill();

    ctx.clip();

    if (!this.opts.isTopStrap) {
      var whiteLeather = document.getElementById("white-leather");

      if (whiteLeather) {
        ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
        ctx.globalAlpha = 0.5;
        ctx.drawImage(whiteLeather,
          0, 0, 300, 30,
          -this.w / 2, -this.h / 2, this.w, this.h);
      }
    }
    ctx.restore();

    if (this.opts.seamColor !== null) {
      this.drawBaseCollarSeam(ctx);
    }
  }

  drawBaseCollarSeam(ctx) {
    ctx.save();
    var seamDist = this.opts.seamDist || 0.1;

    ctx.lineCap = "round";
    ctx.setLineDash([1 / 5, 1 / 10]);
    ctx.strokeStyle = this.seamColor;

    ctx.lineWidth = 1 / 40;

    if (this.opts.addRightRounded) {
      this.pathRoundedRect2(ctx, -this.w / 2 + seamDist, -this.h / 2 + seamDist, this.w - seamDist * 2, this.h - seamDist * 2, 1 / 8, 1 / 2, true);
    } else {
      this.pathRoundedRect(ctx, -this.w / 2 + seamDist, -this.h / 2 + seamDist, this.w - seamDist * 2, this.h - seamDist * 2, 1 / 8);
    }
    ctx.stroke();
    ctx.restore();
  }

  pathRoundedRect2(ctx, x, y, width, height, radius, rightRadius, notClose?: boolean) {
    ctx.beginPath();

    if (notClose !== true) {
      ctx.moveTo(x + radius, y);
    } else {
      ctx.moveTo(x, y);
    }

    ctx.lineTo(x + width - rightRadius * 2, y);

    ctx.bezierCurveTo(
      x + width - rightRadius, y,
      x + width, y + height * 6 / 16,
      x + width, y + height / 2
    );

    ctx.bezierCurveTo(
      x + width, y + height * 10 / 16,
      x + width - rightRadius, y + height,
      x + width - rightRadius * 2, y + height
    );

    if (notClose !== true) {
      ctx.lineTo(x + radius, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
      ctx.closePath();
    } else {
      ctx.lineTo(x, y + height);
    }
  }
}
import { BaseCollarObject } from "./BaseCollarObject";

export class PermanenteEdge extends BaseCollarObject<{ h: number }> {

  get h() { return this.opts.h; }

  drawOverlay(ctx) {
    this.drawAtX(ctx, 0, "rgb(120, 120, 120)");
    this.drawAtX(ctx, 0.05, "rgb(60, 60, 60)");
  }

  drawAtX(ctx, x: number, color: string) {
    const extraSpace = -0.01;
    const ringSize = this.h * 0.33;
    ctx.fillStyle = color;
    ctx.strokeStyle = color;
    ctx.lineWidth = 0.03;
    ctx.setLineDash([0]);

    ctx.beginPath();
    ctx.moveTo(x, - this.h / 2 - extraSpace);
    ctx.bezierCurveTo(
      ringSize, - this.h / 2 - extraSpace,
      ringSize, this.h / 2 + extraSpace,
      x, this.h / 2 + extraSpace
    );
    ctx.stroke();
  }
}

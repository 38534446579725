import { BaseCollarObject } from "./BaseCollarObject";

export class HolesDraw extends BaseCollarObject<{ distance: number; holeSize?: number; drawSlots?: boolean; count: number }> {

  get distance() { return this.opts.distance; }
  get holeSize() { return this.opts.holeSize || (1.0 / 16.0); }

  drawOverlay(ctx) {
    ctx.fillStyle = "rgb(255, 255, 255)";
    ctx.strokeStyle = "rgb(255, 255, 255)";
    ctx.lineCap = "round";

    for (var i = 0; i < this.opts.count; i++) {
      ctx.beginPath();

      if (this.opts.drawSlots) {
        ctx.lineWidth = this.holeSize;
        ctx.moveTo(i * this.distance, -this.holeSize * 1.5);
        ctx.lineTo(i * this.distance, this.holeSize * 1.5);
        ctx.stroke();
      } else {

        ctx.arc(i * this.distance, 0, this.holeSize, 0, 2 * Math.PI);
        ctx.fill();
      }

    }
  }
}
